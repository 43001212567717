<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Agregar Versión de Aplicación</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <MobileAppForm :mobileApp="mobileApp"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import MobileAppForm from '@/components/administrations/mobile-app/MobileAppForm';

    export default {
        name: "MobileAppAdd",
        components: {
            Breadcrumbs,
            MobileAppForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Aplicación Móvil', disabled: false, to: {name: 'MobileApp'}, exact: true},
                {text: 'Crear Versión', disabled: true, to: {name: 'MobileAppAdd'}, exact: true},
            ],
            mobileApp: {
                name: null,
                version: null,
                file: null,
                file_url: null,
            }
        })
    }
</script>

<style scoped>

</style>
