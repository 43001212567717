<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <Datatableindex :options="options" :data="fails" @deleteItem="deleteItemConfirm" @exportExcel="exportExcel"/>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">¿Seguro de borrar este item?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" :disabled="loading" @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="error" :disabled="loading" :loading="loading" @click="deleteItemApi">
                        Sí, estoy seguro
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import tireFailService from "@/services/tires/tireFailService";

    export default {
        name: 'Fail',
        components: {
            Breadcrumbs,
            Datatableindex
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Fallas del Neumático', disabled: true, to: {name: 'TiresFail'}, exact: true}
            ],
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            fails:[],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 15,
                options: {
                    search: '',
                },
                loading: true,
                headers: [
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    {text: 'Parte', value: 'part.name'},
                    {text: 'Falla', value: 'name'},
                    {text: 'Comentarios', value: 'comment'},
                    {text: 'Foto', value: 'photo_url'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                title: 'Fallas del Neumático',
                titleAddButton: 'Agregar Falla',
                addlink: '/tires/fails/create',
                editlink: '/tires/fails/edit/',
                deleteMethod: 'deleteTireFail',
            },
            snackbar: false,
            textError: '',
            firstLoading: true,
        }),
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        created() {
            this.getDataFromApi()
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                tireFailService.datatable(this.options.options).then(response => {
                    this.fails = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            deleteItemConfirm(item) {
                this.dialogDelete = true;
                this.itemDelete = item;
            },
            deleteItemApi() {
                this.loading = true;
                tireFailService.delete(this.itemDelete.id).then(response => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.getDataFromApi();
                }).catch(error => {
                    this.loading = false;
                    this.dialogDelete = false;
                    console.error(error);
                });
            },
            exportExcel() {
                tireFailService.exportExcel().then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Fallas.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.loading = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
