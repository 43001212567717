import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/pressures/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/pressures`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/pressures/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/pressures/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/pressures`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/pressures/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/pressures/${id}`);
    },
}
