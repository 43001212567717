import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/users/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/users`);
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/users/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/users`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/users/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/users/${id}`);
    },
}
