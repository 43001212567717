<template>
    <div class="content">
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        :color="snackbarColor"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <div class="content-image"></div>
        <div class="content-form">
            <v-container grid-list-md text-xs-center class="form-container">
                <v-card align="center" justify="center">
                    <img src="../../assets/images/logopts.png" class="center"/>
                    <v-card-text>
                        <v-alert v-if="showAlert" border="left" :color="colorAlert" dark>
                            {{textError}}
                            <br>
                        </v-alert>

                        <h1 class="text-center">Recuperar Contraseña</h1>
                        <br>
                        <v-form v-model="valid" @submit.prevent="submit">
                            <v-container grid-list-md>
                                <v-row>
                                    <v-col cols="12" md="8" class="mx-auto">
                                        <v-text-field label="Nueva Contraseña" type="password" outlined required v-model="form.password"
                                                      :rules="rules.password"></v-text-field>
                                        <v-text-field label="Confirmar nueva Contraseña" type="password" outlined required v-model="form.password_confirmation"
                                                      :rules="rules.password_confirmation"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="justify-center button-submit login-submit">
                                    <v-col cols="12" md="8" class="mx-auto">
                                        <v-btn block dense depressed type="submit" :disabled="!valid || loading"
                                               :loading="loading">
                                            Recuperar Contraseña
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-container>
        </div>
    </div>
</template>

<script>
    import auth from "@/services/auth";

    export default {
        name: "ResetPassword",
        data: () => ({
            snackbarColor: 'red',
            snackbar: false,
            textError: '',
            showAlert: false,
            loading: false,
            valid: false,
            colorAlert: 'red',
            form: {
                token: null,
                email: null,
                password: null,
                password_confirmation: null,
            },
            rules: {
                password: [
                    v => !!v || 'Contraseña obligatoria',
                ],
                password_confirmation: [
                    v => !!v || 'Confirmación Contraseña obligatoria',
                ],
            }
        }),
        mounted() {
            this.form.email = this.$route.query.email;
            this.form.token = this.$route.query.token;
        },
        methods: {
            submit() {
                if (this.form.password !== this.form.password_confirmation) {
                    this.colorAlert = 'red';
                    this.snackbarColor = 'red';
                    this.showAlert = true;
                    this.textError = 'Las contraseñas son diferentes';
                    this.snackbar = true;
                    return;
                }

                this.loading = true;
                auth.resetPassword(this.form).then(response => {
                    this.snackbarColor = 'green';
                    this.colorAlert = 'green';
                    this.showAlert = true;
                    this.textError = response.data.message;
                    this.snackbar = true;
                    setTimeout(() => this.$router.push('/login'), 1500);
                }).catch(error => {
                    this.colorAlert = 'red';
                    this.snackbarColor = 'red';
                    this.showAlert = true;
                    this.loading = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            }
        }
    }
</script>

<style type="text/css">
    .content {
        display: flex;
        flex-direction: row;
        height: calc(100vh - 3px);
        margin: 0;
        padding: 0;
        top: 0;
        bottom: 0;
    }

    .content-image {
        width: 70%;
        float: left;
        background-image: url("../../assets/images/pts-bg.png");
        /*background-image: url("../../assets/images/ptenza.jpg");*/
        /*background-size: 645px 638px;*/
        background-size: cover;
        /* background: transparent url('../../assets/images/nosotros.jpg') 0% 0% no-repeat padding-box; */
        /* background-image: ; */
    }

    .form-container {
        /*width: 65% !important;*/
    }

    .content-form {
        display: flex;
        align-items: center;
        width: 30%;
        background: var(--unnamed-color-f8f9fa) 0% 0% no-repeat padding-box;
        background-color: #f8f9fa 0% 0% no-repeat padding-box !important;
        float: right;
    }

    .v-card__title {
        text-align: center;
        font: normal normal bold 35px/40px Roboto !important;
        letter-spacing: 0px !important;
        color: #10131a !important;
        opacity: 1 !important;
    }

    .forgot a {
        color: var(--unnamed-color-10131a);
        text-align: center;
        font: normal normal bold 14px/19px Roboto;
        letter-spacing: 0px;
        color: #10131a;
        opacity: 0.5;
    }


    img {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .login-submit .v-btn {
        height: 55px !important;
    }
</style>
