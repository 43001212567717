<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>

        <v-form v-model="valid" @submit.prevent="submit">
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <template v-if="!user.id">
                            <v-select
                                    v-model="user.customer_id"
                                    :items="customers"
                                    outlined
                                    item-text="business_name"
                                    item-value="id"
                                    label="Cliente"
                            ></v-select>
                        </template>
                        <v-text-field
                                v-model="user.name"
                                :rules="rules.name"
                                label="Nombre"
                                required
                                outlined
                        ></v-text-field>
                        <v-text-field
                                v-model="user.dni"
                                :rules="rules.dni"
                                label="DNI"
                                required
                                outlined
                        ></v-text-field>

                        <v-text-field
                                :readonly="!!user.id"
                                v-model="user.email"
                                :rules="rules.email"
                                label="Correo"
                                required
                                outlined
                        ></v-text-field>
                        <v-select
                                v-model="user.status"
                                :items="allstatus"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Estado"
                        ></v-select>
                        <template v-if="!user.id">
                            <v-text-field
                                    v-model="user.password"
                                    :rules="rules.password"
                                    label="Contraseña"
                                    type="password"
                                    outlined
                            ></v-text-field>

                            <v-text-field
                                    v-model="user.password_confirmation"
                                    :rules="rules.password_confirmation"
                                    label="Contraseña"
                                    type="password"
                                    outlined
                            ></v-text-field>
                        </template>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn :disabled="!valid || loading" type="submit" v-if="!user.id" :loading="loading">
                                Guardar
                            </v-btn>
                        </div>
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn :disabled="!valid || loading" type="submit" v-if="user.id" :loading="loading">
                                Actualizar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import customerService from "@/services/administrations/customerService";

    export default {
        name: "CustomerUserForm",
        props: ["user"],
        components: {},
        data: () => ({
            snackbar: false,
            textError: '',
            customers: [],
            loading: false,
            valid: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
                dni: [
                    v => !!v || 'DNI es requerido',
                ],
                email: [
                    v => !!v || 'Correo es requerido',
                ],
                password: [
                    v => !!v || 'Contraseña es requerida',
                ],
                password_confirmation: [
                    v => !!v || 'Confirmación de contraseña es requerida',
                ],
            },
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ]
        }),
        mounted() {
            console.log(this.user);
            if(!this.user.id) {
                customerService.all().then(response => {
                    this.customers = response.data.data;
                }).catch(error => {
                    console.error('error', error);
                });
            }
        },
        methods: {
            submit() {
                this.loading = true;
                if (this.user.id) {
                    customerService.updateUser(this.user).then(response => {
                        this.$router.push({path: '/administration/customer/user'})
                    }).catch(error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                } else {
                    customerService.storeUser(this.user).then(response => {
                        this.$router.push({path: '/administration/customer/user'})
                    }).catch(error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
