<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <Datatableindex :options="options" :data="wears" @deleteItem="deleteItemConfirm" @exportExcel="exportExcel"/>

        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">¿Seguro de borrar este item?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" :disabled="loading" @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="error" :disabled="loading" :loading="loading" @click="deleteItemApi">
                        Sí, estoy seguro
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import tireWearService from "@/services/tires/tireWearService";

    export default {
        name: 'Wear',
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Tipo de Desgastes', disabled: true, to: {name: 'TiresWear'}, exact: true}
            ],
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            wears:[],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 15,
                options: {
                    search: '',
                },
                loading: true,
                headers: [
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    {text: 'Tipo', value: 'name'},
                    {text: 'Observaciones', value: 'observation'},
                    {text: 'Foto', value: 'photo_url'},
                    {text: 'Estado', value: 'status_nice'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                title: 'Tipo de Desgaste',
                titleAddButton: 'Agregar Desgaste',
                addlink: '/tires/wears/create',
                editlink: '/tires/wears/edit/',
                deleteMethod: 'deleteTireWear',
            },
            snackbar: false,
            textError: '',
            firstLoading: true,
        }),
        mounted() {
            this.getDataFromApi()
        },
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                tireWearService.datatable(this.options.options).then(response => {
                    this.wears = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            deleteItemConfirm(item) {
                this.dialogDelete = true;
                this.itemDelete = item;
            },
            deleteItemApi() {
                this.loading = true;
                tireWearService.delete(this.itemDelete.id).then(response => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.getDataFromApi();
                }).catch(error => {
                    this.loading = false;
                    this.dialogDelete = false;
                    console.error(error);
                });
            },
            exportExcel() {
                tireWearService.exportExcel().then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Tipos de Desgaste.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.loading = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            }
        }
    }
</script>


<style type="text/css">


</style>
