<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
          <v-container>
             <v-row>
                <v-col cols="12" sm="6" d="8" >
                    <h1>Editar Control</h1>
                </v-col>
            </v-row>
             <v-row align="center" justify="center">
                 <v-card width='90%'>
                        <PesajeForm :pesaje="pesaje"/>
                 </v-card>
             </v-row>
          </v-container>

    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import PesajeForm from '@/components/process/pesaje/PesajeForm';
    import processPesajeService from '@/services/processPesajeService'
    export default {
        name:'pesajeEdit',
        components:{
            Breadcrumbs,
            PesajeForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Marcas', disabled: false, to: {name:'ProcessPesaje'}, exact: true},
                {text: 'Editar Marca', disabled: true,to: {name:'ProcessPesajeEdit'}, exact: true}
            ],
            pesaje:{
                customer_id: null,
                id: null,
                name:'',
                status:null,
                statusNice:'',
                retreading: true,
                logoImage:'',
                logoImageUrl:''
            },
        }),
        created (){
            this.getprocessPesajeById(this.$route.params.id);
        },
        methods: {
            getprocessPesajeById(id){
                processPesajeService.getprocessPesajeById(id).then(response => {
                    this.pesaje = response.data
                })
            }
        }
    }
</script>

<style scoped>

</style>
