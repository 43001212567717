<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red"
                       text
                       v-bind="attrs"
                       @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <Datatableindex :options="options" :data="inspections" :withCustomerSelect="isAdmin"
                        @deleteItem="deleteItemConfirm"
                        @onSelectCustomerId="onSelectCustomerId" @exportExcel="exportExcel"/>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">¿Seguro de borrar este item?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" :disabled="loading" @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="error" :disabled="loading" :loading="loading" @click="deleteItemApi">
                        Sí, estoy seguro
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import inspectionService from "@/services/inspectionService";

    export default {
        name: "Inspection",
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Control e Inspección', disabled: true, to: {name: 'Inspection'}, exact: true}
            ],
            snackbar: false,
            textError: '',
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            inspections: [],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                options: {
                    customer_id: null,
                    search: '',
                },
                loading: true,
                headers: [
                    {text: 'Empresa', value: 'customer.business_name'},
                    {text: 'N° Informe', value: 'report_number'},
                    {text: 'Fecha', value: 'date'},
                    {text: 'Placa', value: 'vehicle.license_plate'},
                    {text: 'Numero', value: 'vehicle.vehicle_number'},
                    {text: 'Marca', value: 'vehicle.brand_of_unit.name'},
                    {text: 'Modelo', value: 'vehicle.unit_model.name'},
                    {text: 'Tipo Eje', value: 'vehicle.unit_type_axis.name'},
                    {text: 'Tipo Posiciones', value: 'vehicle.unit_type_axis.position'},
                    {text: 'Actions', value: 'actions', sortable: false},

                ],
                title: 'Control e Inspección',
                titleAddButton: 'Agregar Control',
                addlink: '/process/inspections/create',
                editlink: '/process/inspections/edit/',
            },
            user: null,
            isAdmin: false,
            firstLoading: true,
        }),
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.getDataFromApi();
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if (role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
            /*if (this.user.roles.find(role => (role.name === 'admin' || role.name === 'inspector') && role.name !== 'customer')) {
                this.isAdmin = true;
            }*/
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                inspectionService.datatable(this.options.options).then(response => {
                    this.inspections = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            onSelectCustomerId(e) {
                this.options.options.customer_id = e;
                this.getDataFromApi();
            },
            deleteItemConfirm(item) {
                this.dialogDelete = true;
                this.itemDelete = item;
            },
            deleteItemApi() {
                this.loading = true;
                inspectionService.delete(this.itemDelete.id).then(response => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.getDataFromApi();
                }).catch(error => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    console.error(error);
                });
            },
            exportExcel() {
                inspectionService.exportExcel(this.options.options).then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Inspecciones.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.loading = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>
