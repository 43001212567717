<template>
    <v-form v-model="valid" @submit.prevent="submit">
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-text-field
                            v-model="unitroute.name"
                            :rules="rules.name"
                            label="Ruta"
                            required
                            outlined
                    ></v-text-field>

                    <v-select
                            v-model="unitroute.status"
                            :items="allstatus"
                            outlined
                            item-text="name"
                            item-value="id"
                            label="Estado"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                               :disabled="!valid || loading"
                               v-if="!unitroute.id" :loading="loading"
                        >
                            Guardar
                        </v-btn>
                    </div>
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                               :disabled="!valid || loading"
                               v-if="unitroute.id" :loading="loading"
                        >
                            Actualizar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import unitRouteService from '@/services/units/unitRouteService'

    export default {
        props: ["unitroute"],
        name: 'UnitRouteForm',
        components: {},
        data: () => ({
            loading: false,
            valid: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
            },
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ]
        }),
        methods: {
            submit() {
                this.loading = true;
                if (this.unitroute.id) {
                    unitRouteService.update(this.unitroute).then(response => {
                        this.$router.push({path: '/units/routes'})
                    }).catch(error => {
                        console.log('error', error);
                        this.loading = false;
                    });
                } else {
                    unitRouteService.store(this.unitroute).then(response => {
                        this.$router.push({path: '/units/routes'})
                    }).catch(error => {
                        console.error('error', error);
                        this.loading = false;
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>
