<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" @submit.prevent="submit">
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <v-select
                                v-if="showCustomerId"
                                v-model="unit.customer_id"
                                :items="customers"
                                :rules="customerIdRules"
                                v-on:change="getFleetsByCustomerId"
                                outlined
                                item-text="business_name"
                                item-value="id"
                                label="Cliente"
                        ></v-select>

                        <v-text-field
                                v-model="unit.license_plate"
                                :rules="rules.license_plate"
                                label="Placa"
                                required
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="unit.vehicle_number"
                                label="Número"
                                required
                                outlined
                        ></v-text-field>

                        <v-select
                                v-model="unit.brand_of_unit_id"
                                :items="brands"
                                :rules="rules.brand_of_unit_id"
                                v-on:change="getByBrand"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Marca"
                        ></v-select>

                        <v-select
                                v-model="unit.unit_model_id"
                                :items="models"
                                :rules="rules.unit_model_id"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Modelo"
                        ></v-select>

                        <v-select
                                v-model="unit.unit_type_id"
                                :items="types"
                                :rules="rules.unit_type_id"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Tipo"
                        ></v-select>

                        <v-select
                                v-model="unit.unit_type_axis_id"
                                :items="typeAxises"
                                :rules="rules.unit_type_axis_id"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Tipo de Eje"
                        ></v-select>


                        <v-select
                                v-model="unit.fleet_id"
                                :items="fleets"
                                :rules="rules.fleet_id"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Operación"
                        ></v-select>

                        <v-text-field
                                v-model="unit.flats"
                                :rules="rules.flats"
                                label="Piso"
                                required
                                outlined
                        ></v-text-field>

                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="!unit.id" :loading="loading">
                                Guardar
                            </v-btn>
                        </div>
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="unit.id" :loading="loading">
                                Actualizar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import customerService from '@/services/administrations/customerService';
    import unitBrandService from '@/services/units/unitBrandService';
    import unitModelService from '@/services/units/unitModelService';
    import unitTypeService from '@/services/units/unitTypeService';
    import unitWearLevelService from '@/services/units/unitWearLevelService';
    import fleetService from '@/services/fleetService';
    import unitService from '@/services/units/unitService';

    export default {
        name: "UnitForm",
        props: ["unit"],
        components: {},
        data: () => ({
            showCustomerId: false,
            snackbar: false,
            textError: '',
            loading: false,
            valid: false,
            customers: [],
            brands: [],
            models: [],
            types: [],
            typeAxises: [],
            fleets: [],
            rules: {
                license_plate: [
                    v => !!v || 'Placa es requerido',
                ],
                brand_of_unit_id: [
                    v => !!v || 'Marca es requerida',
                ],
                unit_model_id: [
                    v => !!v || 'Modelo es requerido',
                ],
                unit_type_id: [
                    v => !!v || 'Tipo es requerido',
                ],
                unit_type_axis_id: [
                    v => !!v || 'Tipo de eje es requerido',
                ],
                fleet_id: [
                    v => !!v || 'Operación requerida',
                ],
                flats: [
                    v => !!v || 'Pisos es requerido',
                ],
            },
            customerIdRules: [
                v => !!v || 'Cliente es requerido',
            ],
            allstatus: [],

            user: null,
            isAdmin: false,
        }),
        mounted() {
            if(this.unit.id) {
                this.getByBrand();
            }
            unitBrandService.all().then(response => {
                this.brands = response.data.data;
            }).catch(error => {
                console.error(error);
            });
            unitTypeService.all().then(response => {
                this.types = response.data.data;
            }).catch(error => {
                console.error(error);
            });
            unitWearLevelService.all().then(response => {
                this.typeAxises = response.data.data;
            }).catch(error => {
                console.error(error);
            });
            /*fleetService.all().then(response => {
                this.fleets = response.data.data;
            }).catch(error => {
                console.error(error);
            });*/
            this.getCustomers();
        },
        methods: {
            getCustomers() {
                this.user = this.$store.getters.userSession;

                this.isAdmin = true;
                this.user.roles.forEach(role => {
                    if(role.name === 'customer') {
                        this.isAdmin = false;
                    }
                });

                if (this.unit.id !== 0 && this.isAdmin) {
                    this.showCustomerId = true;
                    customerService.all().then(response => {
                        this.customers = response.data.data;
                    }).catch(error => {
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });

                    fleetService.all(this.unit.customer_id).then(response => {
                        this.fleets = response.data.data;
                    }).catch(error => {
                        console.error(error);
                    });
                }

                if (!this.isAdmin) {
                    fleetService.all().then(response => {
                        this.fleets = response.data.data;
                    }).catch(error => {
                        console.error(error);
                    });
                }
            },
            getByBrand() {
                unitModelService.allByBrand(this.unit.brand_of_unit_id).then(response => {
                    this.models = response.data.data;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
            getFleetsByCustomerId() {
                fleetService.all(this.unit.customer_id).then(response => {
                    this.fleets = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            },
            submit() {
                this.loading = true;
                if (this.unit.id) {
                    unitService.update(this.unit).then(response => {
                        this.$router.push({path: '/units'})
                    }, error => {
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                        this.loading = false;
                    });
                } else {
                    unitService.store(this.unit).then(response => {
                        this.$router.push({path: '/units'})
                    }, error => {
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                        this.loading = false;
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>
