<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <Datatableindex :options="options" :data="fleets" @deleteItem="deleteItemConfirm" :withCustomerSelect="isAdmin"
                        @onSelectCustomerId="onSelectCustomerId" @exportExcel="exportExcel"/>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">¿Seguro de borrar este item?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" :disabled="loading" @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="error" :disabled="loading" :loading="loading" @click="deleteItemApi">
                        Sí, estoy seguro
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import fleetService from "@/services/fleetService";

    export default {
        name: "Fleets",
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Operación', disabled: true, to: {name: 'Fleets'}, exact: true}
            ],
            snackbar: false,
            textError: '',
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            fleets: [],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 15,
                options: {
                    search: '',
                    customer_id: null
                },
                loading: true,
                headers: [
                    {text: 'Empresa', value: 'customer.business_name'},
                    {text: 'Nombre de Operación', value: 'name'},
                    {text: 'Estado', value: 'status_nice'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                title: 'Operación',
                titleAddButton: 'Agregar Operación',
                addlink: '/fleets/create',
                editlink: '/fleets/edit/',
            },
            user: null,
            isAdmin: false,
            firstLoading: true,
        }),
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.getDataFromApi();
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                fleetService.datatable(this.options.options).then(response => {
                    this.fleets = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            onSelectCustomerId(e) {
                this.options.options.customer_id = e;
                this.getDataFromApi();
            },
            deleteItemConfirm(item) {
                this.dialogDelete = true;
                this.itemDelete = item;
            },
            deleteItemApi() {
                this.loading = true;
                fleetService.delete(this.itemDelete.id).then(response => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.getDataFromApi();
                }).catch(error => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    console.error(error);
                });
            },
            exportExcel() {
                fleetService.exportExcel(this.options.options).then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Operación.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.loading = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            }
        }
    };
</script>

<style scoped>
    .button-add {
        background: var(--unnamed-color-ed1e2a) 0% 0% no-repeat padding-box !important;
        background: #ed1e2A 0% 0% no-repeat padding-box !important;
        border-radius: 5px !important;
        opacity: 1 !important;
        width: 238px !important;
        height: 50px !important;
        color: #ffffff !important;
    }

    .v-btn__content {
        color: var(--unnamed-color-ffffff) !important;
        text-align: center !important;
        font: normal normal bold 18px/24px Roboto !important;
        letter-spacing: 0px !important;
        color: #ffffff !important;
        opacity: 1 !important;
    }


</style>
