<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-form @submit.prevent="submit">
                        <v-row>
                            <v-col v-if="isAdmin" cols="12" md="4">
                                <v-select
                                        v-model="form.customer_id"
                                        v-on:change="onChangeCustomer"
                                        :items="customers"
                                        item-text="business_name"
                                        item-value="id"
                                        label="Cliente"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-menu v-model="fromDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                label="Desde"
                                                prepend-icon="event"
                                                readonly
                                                :value="from_date_value"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            locale="es-ES"
                                            v-model="form.from_date"
                                            no-title
                                            @input="fromDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-menu v-model="toDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                label="Hasta"
                                                prepend-icon="event"
                                                readonly
                                                :value="to_date_value"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            locale="es-ES"
                                            v-model="form.to_date"
                                            no-title
                                            @input="toDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <template v-if="isAdmin">
                                <v-col cols="12" md="12">
                                    <label>Conclusiones</label>
                                    <quill-editor
                                            ref="myQuillEditor"
                                            v-model="form.conclusions"
                                            :options="editorOption"
                                    />
                                </v-col>
                                <v-col cols="12" md="12">
                                    <label>Recomendaciones</label>
                                    <quill-editor
                                            ref="myQuillEditor"
                                            v-model="form.recommendations"
                                            :options="editorOption"
                                    />
                                </v-col>
                            </template>
                            <v-col cols="12" md="3">
                                <v-checkbox v-for="type in form.types"
                                            :key="type.id"
                                            v-model="type.selected"
                                            :disabled="type.disabled"
                                            :label="type.name"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                        v-model="form.minor_rtd"
                                        type="number"
                                        label="RTD Menor"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                        v-model="form.major_rtd"
                                        type="number"
                                        label="RTD Mayor"
                                        required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-btn depressed color="primary" type="submit" :disabled="loading" :loading="loading">
                                    Exportar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import inspectionService from "@/services/inspectionService";
    import customerService from "@/services/administrations/customerService";
    import unitTypeService from "@/services/units/unitTypeService";
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import {quillEditor} from 'vue-quill-editor';

    export default {
        name: "AuditInspection",
        components: {
            Breadcrumbs,
            quillEditor
        },
        data: () => ({
            breadcrumbs: [
                {
                    text: 'Reporte de Auditoría de Inspecciones',
                    disabled: true,
                    to: {name: 'AuditInspection'},
                    exact: true
                }
            ],
            loading: false,
            snackbar: false,
            textError: '',
            fromDateMenu: false,
            toDateMenu: false,
            form: {
                customer_id: null,
                from_date: null,
                to_date: null,
                minor_rtd: null,
                major_rtd: null,
                conclusions: null,
                recommendations: null,
                types: [],
            },
            user: null,
            isAdmin: false,
            customers: [],
            editorOption: {}
        }),
        computed: {
            from_date_value() {
                return this.formatDate(this.form.from_date);
            },
            to_date_value() {
                return this.formatDate(this.form.to_date);
            }
        },
        mounted() {
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
            if (this.isAdmin) {
                this.isAdmin = true;
                customerService.all().then(response => {
                    this.customers = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }

            unitTypeService.all().then(response => {
                this.form.types = response.data.data;
                this.form.types.forEach(type => {
                    type.selected = true;
                });
            }).catch(error => {
                console.error(error);
            });
        },
        methods: {
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
            onChangeCustomer() {
                const customerFound = this.customers.find(customer => customer.id == this.form.customer_id);
                if (customerFound) {
                    this.form.minor_rtd = customerFound.minor_rtd;
                    this.form.major_rtd = customerFound.major_rtd;
                }
            },
            submit() {
                if (this.isAdmin && !this.form.customer_id) {
                    this.textError = 'Debe seleccionar un cliente';
                    this.snackbar = true;
                    return;
                }

                if (!this.form.from_date || !this.form.to_date) {
                    this.textError = 'Selecciona un rango de fecha';
                    this.snackbar = true;
                    return;
                }

                if (!this.form.minor_rtd || !this.form.major_rtd) {
                    this.textError = 'Complete los campos de mayor y menor RTD';
                    this.snackbar = true;
                    return;
                }

                let form = Object.assign({}, this.form);
                form.types = [];
                this.form.types.forEach(item => {
                    if (item.selected) {
                        form.types.push(item.id)
                    }
                });
                this.loading = true;
                inspectionService.downloadReport(form).then(response => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Reporte de Auditoría.pdf';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.loading = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>
