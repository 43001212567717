import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/drivers/datatable`, data);
    },

    all(customerId = null) {
        if (customerId == null) {
            customerId = '';
        }
        return axios.get(`${ENDPOINT_PATH}/drivers`, {
            params: {
                customer_id: customerId
            }
        });
    },

    exportExcel(form) {
        return axios.get(`${ENDPOINT_PATH}/drivers/export-list`, {
            responseType: 'blob',
            params: form
        });
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/drivers/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/drivers`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/drivers/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/drivers/${id}`);
    },
}
