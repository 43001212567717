import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/inspections/datatable`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/inspections/${data.id}`, data);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/inspections`, data);
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/inspections/${id}`);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/inspections/${id}`);
    },

    exportExcel(form) {
        return axios.get(`${ENDPOINT_PATH}/inspections/export-list`, {
            responseType: 'blob',
            params: form
        });
    },

    downloadReport(form) {
        return axios.post(`${ENDPOINT_PATH}/inspections/report`, form, {
            responseType: 'blob'
        });
    },

    downloadProjectionReport(form) {
        return axios.get(`${ENDPOINT_PATH}/inspections/projection`, {
            params: form,
            responseType: 'blob'
        });
    },

    downloadIrregularWearReport(form) {
        return axios.get(`${ENDPOINT_PATH}/inspections/irregular-wear`, {
            params: form,
            responseType: 'blob'
        });
    }
}
