<template>
    <v-form v-model="valid" @submit.prevent="submit">
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-select
                            v-model="fail.part_id"
                            :items="parts"
                            outlined
                            item-text="name"
                            item-value="id"
                            label="Parte"
                    ></v-select>

                    <v-text-field
                            v-model="fail.name"
                            :rules="rules.name"
                            label="Falla"
                            required
                            outlined
                    ></v-text-field>

                    <v-file-input
                            accept="image/*"
                            label="Foto"
                            @change="handleImage($event)"
                            outlined
                            prepend-icon="mdi-camera"
                    ></v-file-input>

                    <div v-if="fail.photo_url" class="centerdiv-container">
                        <v-img
                                max-width="22rem"
                                v-bind:src="fail.photo_url"
                        ></v-img>
                    </div>

                    <v-select
                            v-model="fail.status"
                            :items="allstatus"
                            outlined
                            item-text="name"
                            item-value="id"
                            label="Estado"
                    ></v-select>
                    <v-textarea
                            outlined
                            v-model="fail.comment"
                            name="input-7-4"
                            label="Comentario"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                               :disabled="!valid || loading"
                               :loading="loading"
                               v-if="!fail.id">
                            Guardar
                        </v-btn>
                    </div>
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                               :disabled="!valid || loading"
                               :loading="loading"
                               v-if="fail.id"
                        >
                            Actualizar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import tireFailService from '@/services/tires/tireFailService'
    import tirePartService from "@/services/tires/tirePartService";
    import resourceService from '@/services/resourceService'

    export default {
        props: ["fail"],
        name: 'FailForm',
        components: {},
        data: () => ({
            loading: false,
            valid: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
            },
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ],
            parts: []
        }),
        mounted() {
            this.getParts();
        },
        methods: {
            submit() {
                this.loading = true;
                if (this.fail.id) {
                    tireFailService.update(this.fail).then(response => {
                        this.$router.push({path: '/tires/fails'})
                    }, error => {
                        this.loading = false;
                        console.log('error', error);
                    });
                } else {
                    tireFailService.store(this.fail).then(response => {
                        this.$router.push({path: '/tires/fails'})
                    }, error => {
                        this.loading = false;
                        console.error('error', error);
                    });
                }
            },
            getParts() {
                tirePartService.all().then(response => {
                    this.parts = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            },
            handleImage(e) {
                const formData = new FormData();
                formData.append('file', e);
                formData.append('path', 'fails');
                resourceService.uploadFile(formData).then(response => {
                    this.fail.photo = response.data.data.name;
                    this.fail.photo_url = response.data.data.url;
                }).catch(error => {
                    console.error('error', error);
                });
            },
        }
    }
</script>

<style scoped>

</style>
