var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","d":"8"}},[_c('h1',[_vm._v(_vm._s(_vm.options.title))])])],1),(_vm.withCustomerSelect)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.customers,"item-text":"business_name","item-value":"id","label":"Seleccione el Cliente"},on:{"change":_vm.selectCustomer},model:{value:(_vm.customerId),callback:function ($$v) {_vm.customerId=$$v},expression:"customerId"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar...","solo":"","append-icon":"search"},model:{value:(_vm.options.options.search),callback:function ($$v) {_vm.$set(_vm.options.options, "search", $$v)},expression:"options.options.search"}})],1),(_vm.withExportParam)?_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-btn',{staticClass:"button-add",on:{"click":_vm.exportExcel}},[_vm._v("Exportar")])],1):_vm._e(),(_vm.options.addlink)?_c('v-col',{staticClass:"button-submit d-flex",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-btn',{staticClass:"button-add ml-auto",attrs:{"to":_vm.options.addlink}},[_vm._v("+"+_vm._s(_vm.options.titleAddButton))])],1):_vm._e()],1),_c('v-row',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"page":_vm.options.page,"items-per-page":_vm.options.itemsPerPage,"headers":_vm.options.headers,"items":_vm.data,"options":_vm.options.options,"loading":_vm.options.loading,"server-items-length":_vm.options.itemsLength},on:{"update:options":function($event){return _vm.$set(_vm.options, "options", $event)}},scopedSlots:_vm._u([{key:"item.retreading",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.retreading),callback:function ($$v) {_vm.$set(item, "retreading", $$v)},expression:"item.retreading"}})]}},{key:"item.customer_user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_user[0].customer.business_name)+" ")]}},{key:"item.otd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.otd != null ? item.otd.toFixed(1) : item.otd)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status_nice)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":"" + (_vm.options.editlink) + item.id}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),(_vm.hasDeleteItemCom)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1):_vm._e(),(_vm.options.extraLink)?_c('v-btn',{attrs:{"icon":"","to":"" + (_vm.options.extraLink) + item.id}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-wrench ")])],1):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(item.date,'DD/MM/YYYY'))+" ")]}},{key:"item.photo_url",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"img",attrs:{"src":item.photo_url,"alt":""}})]}},{key:"item.file_url",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"href":item.file_url,"elevation":"2"}},[_vm._v("Descargar")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }