<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Agregar Chofer</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <DriverForm :driver="driver"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import DriverForm from '@/components/drivers/DriverForm';

    export default {
        name: "DriverAdd",
        components: {
            Breadcrumbs,
            DriverForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Choferes', disabled: false, to: {name: 'Drivers'}, exact: true},
                {text: 'Crear Chofer', disabled: true, to: {name: 'DriverAdd'}, exact: true},
            ],
            driver: {
                id: null,
                customer_id: '',
                name: '',
                paternal_surname: '',
                maternal_surname: '',
                dni: '',
            }
        }),

    }
</script>

<style scoped>

</style>
