<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <div class="text-center">
                        <v-btn v-if="href" :href="href" elevation="2">Descargar Aplicación de Inspector</v-btn>
                        <h4 v-else>Aún no han cargado ninguna versión de la App para Inspectores</h4>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Breadcrumbs from '@/components/layout/Breadcrumbs';
import mobileAppService from "@/services/administrations/mobileAppService";

export default {
    name: "InspectorMobileApp",
    components: {
        Breadcrumbs,
    },
    data: () => ({
        breadcrumbs: [
            {text: 'Aplicación Móvil', disabled: true, to: {name: 'InspectorMobileApp'}, exact: true}
        ],
        href: null,
        dialog: false,
        overlay: false,
        snackbar: false,
        textError: '',
    }),
    mounted() {
        mobileAppService.getLastVersion().then(response => {
            this.href = response.data.data.url;
        }).catch(error => {
            this.overlay = false;
            this.textError = error.response.data.message;
            this.snackbar = true;
        });
    }
}
</script>

<style scoped>

</style>
