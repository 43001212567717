<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <Datatableindex :options="options" :data="users" :hasDeleteItem="false" :withExport="false"/>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import userService from "@/services/administrations/userService";

    export default {
        name: "Inspector",
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inspectores', disabled: true, to: {name: 'Inspector'}, exact: true}
            ],
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            users: [],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 15,
                options: {
                    search: '',
                    roles: ['inspector'],
                    without_relation: 'customer'
                },
                loading: true,
                headers: [
                    {text: 'Nombres', value: 'name'},
                    {text: 'Correo', value: 'email'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                title: 'Inspectores',
                titleAddButton: 'Agregar Inspector',
                addlink: '/administration/inspectors/create',
                editlink: '/administration/inspectors/edit/',
            },
            firstLoading: true,
        }),
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        created() {
            this.getDataFromApi();
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                userService.datatable(this.options.options).then(response => {
                    this.users = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            /*deleteItemConfirm(item) {
                this.dialogDelete = true;
                this.itemDelete = item;
            },
            deleteItemApi() {
                this.loading = true;
                userService.delete(this.itemDelete.id).then(response => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.getDataFromApi();
                }).catch(error => {
                    this.loading = false;
                    this.dialogDelete = false;
                    console.error(error);
                });
            }*/
        }
    }
</script>

<style scoped>

</style>
