<template>
    <v-form v-model="valid" @submit.prevent="submit">
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-select
                            v-if="showCustomerId"
                            v-model="driver.customer_id"
                            :items="customers"
                            outlined
                            item-text="business_name"
                            item-value="id"
                            label="Cliente"
                    ></v-select>
                    <v-text-field
                            v-model="driver.dni"
                            :rules="rules.dni"
                            label="DNI"
                            v-intNumber
                            required
                            outlined
                    ></v-text-field>
                    <v-text-field
                            v-model="driver.name"
                            label="Nombre"
                            required
                            outlined
                    ></v-text-field>
                    <v-text-field
                            v-model="driver.paternal_surname"
                            label="A. Paterno"
                            required
                            outlined
                    ></v-text-field>
                    <v-text-field
                            v-model="driver.maternal_surname"
                            label="A. Materno"
                            required
                            outlined
                    ></v-text-field>

                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                                :disabled="!valid || loading"
                                v-if="!driver.id" :loading="loading"
                        >
                            Guardar
                        </v-btn>
                    </div>
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                                :disabled="!valid || loading"
                                v-if="driver.id" :loading="loading"
                        >
                            Actualizar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import driverService from '@/services/driverService'
    import customerService from "@/services/administrations/customerService";

    export default {
        name: "DriverForm",
        props: ["driver"],
        components: {},
        data: () => ({
            customers: [],
            showCustomerId: false,
            loading: false,
            valid: false,
            rules: {
                dni: [
                    v => !!v || 'DNI es requerido',
                ],
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
                paternal_surname: [
                    v => !!v || 'Apellido paterno es requerido',
                ],
                maternal_surname: [
                    v => !!v || 'Apellido materno es requerido',
                ],
            },
        }),
        mounted() {
            this.getCustomers();
        },
        methods: {
            getCustomers() {
                this.user = this.$store.getters.userSession;
                this.isAdmin = true;
                this.user.roles.forEach(role => {
                    if(role.name === 'customer') {
                        this.isAdmin = false;
                    }
                });
                if (this.isAdmin) {
                    this.showCustomerId = true;
                    customerService.all().then(response => {
                        this.customers = response.data.data;
                    }).catch(error => {
                        console.error(error);
                    });
                }
            },
            submit() {
                this.loading = true;
                if (this.driver.id) {
                    driverService.update(this.driver).then(response => {
                        this.$router.push({path: '/drivers'})
                    }).catch(error => {
                        console.log('error', error);
                        this.loading = false;
                    });
                } else {
                    driverService.store(this.driver).then(response => {
                        this.$router.push({path: '/drivers'})
                    }).catch(error => {
                        console.error('error', error);
                        this.loading = false;
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>
