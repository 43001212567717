<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Agregar Inspector</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UserForm :user="user" :role_name="'inspector'" :urlRedirect="'/administration/inspectors'"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UserForm from '@/components/administrations/user/UserForm';

    export default {
        name: "InspectorAdd",
        components: {
            Breadcrumbs,
            UserForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inspectores', disabled: false, to: {name: 'Inspector'}, exact: true},
                {text: 'Crear Inspector', disabled: true, to: {name: 'InspectorAdd'}, exact: true},
            ],
            user: {
                name: '',
                dni: '',
                email: '',
                status: 1,
                password: '',
                password_confirmation: '',
            }
        })
    }
</script>

<style scoped>

</style>
