<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container class="mb-2">
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>{{options.title}}</h1>
                </v-col>
            </v-row>
            <v-row v-if="isAdmin">
                <v-col cols="12" sm="12" md="6">
                    <v-select
                            v-model="options.options.customer_id"
                            :items="customers"
                            @change="selectCustomer"
                            item-text="business_name"
                            item-value="id"
                            label="Seleccione el Cliente"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field label="Buscar..." v-model="options.options.search"
                                  append-icon="search"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-container>
                    <v-data-table
                            :page="options.page"
                            :items-per-page="options.itemsPerPage"
                            :headers="options.headers"
                            :items="tires"
                            :options.sync="options.options"
                            :loading="options.loading"
                            :server-items-length="options.itemsLength"
                            :item-class="itemRowBackground"
                            class="elevation-1">
                        <template v-slot:item.retreading="{ item }">
                            <v-simple-checkbox
                                    v-model="item.retreading"
                                    disabled
                            ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn depressed x-small @click="showMovements(item.id)">
                                Ver Detalle
                            </v-btn>
                            <v-btn depressed x-small v-if="item.status == 2" @click="retread(item)">
                                Reencauche
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-container>
            </v-row>
        </v-container>
        <v-container ref="containerMovements">
            <h3>Movimientos {{currentTire.fire_number}}</h3>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Mov.</th>
                        <th class="text-left">Fecha</th>
                        <th class="text-left">Estado</th>
                        <th class="text-left">Unidad</th>
                        <th class="text-left">Posicion</th>
                        <th class="text-left">Km In</th>
                        <th class="text-left">Km Out</th>
                        <th class="text-left">Reset</th>
                        <th class="text-left">Km Recorrido</th>
                        <th class="text-left">Horas In</th>
                        <th class="text-left">Horas Out</th>
                        <th class="text-left">Horas Recorridas</th>
                        <th class="text-left">Marca Reencauche</th>
                        <th class="text-left">Diseño Reencauche</th>
                        <th class="text-left">Costo</th>
                        <th class="text-left">RTD Retiro</th>
                        <th class="text-left">Parte Neumatico (Retiro)</th>
                        <th class="text-left">Falle Neumatico (Retiro)</th>
                        <th class="text-left">Causal Neumatico (Retiro)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in movements">
                        <td>{{item.movement_number}}</td>
                        <td>{{item.date | dateFilter('DD/MM/YYYY')}}</td>
                        <td>{{item.status_nice}}</td>
                        <td>{{item.vehicle ? item.vehicle.license_plate : ''}}</td>
                        <td>{{item.position}}</td>
                        <td>{{item.km_in}}</td>
                        <td>{{item.km_out}}</td>
                        <td>{{item.reset}}</td>
                        <td>{{item.km_travel}}</td>
                        <td>{{item.time_in}}</td>
                        <td>{{item.time_out}}</td>
                        <td>{{item.time_travel}}</td>
                        <td>{{item.brand ? item.brand.name : ''}}</td>
                        <td>{{item.design ? item.design.name : ''}}</td>
                        <td>{{item.cost}}</td>
                        <td>{{item.rtd ? item.rtd : ''}}</td>
                        <td>{{item.part ? item.part.name : ''}}</td>
                        <td>{{item.fail ? item.fail.name : ''}}</td>
                        <td>{{item.causal ? item.causal.name : ''}}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-container>

        <v-dialog v-model="dialog" persistent max-width="450">
            <v-card>
                <v-card-title class="headline">
                    Datos de Reencauche
                </v-card-title>
                <v-card-text>
                    <v-col cols="12">
                        <v-select
                                v-model="retread_form.brand_id"
                                :items="brands"
                                v-on:change="getByBrand"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Marca"
                        ></v-select>
                        <v-select
                                v-model="retread_form.design_id"
                                :items="designs"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Diseño"
                        ></v-select>

                        <v-text-field
                                v-model="retread_form.cost"
                                label="Costo"
                                type="number"
                                required
                                outlined
                        ></v-text-field>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" color="green darken-1" text @click="cancelRetread" :disabled="sendingRetread">
                        Cancelar
                    </v-btn>
                    <v-btn type="submit" color="green darken-1" text @click="sendRetread" :disabled="sendingRetread"
                           :loading="sendingRetread">Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import tireService from "@/services/tires/tireService";
    import tireBrandService from "@/services/tires/tireBrandService";
    import tireDesignService from "@/services/tires/tireDesignService";
    import customerService from "@/services/administrations/customerService";

    export default {
        name: "Movements",
        components: {
            Breadcrumbs,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Movimientos de Neumáticos', disabled: true, to: {name: 'Movements'}, exact: true}
            ],
            dialog: false,
            overlay: false,
            snackbar: false,
            textError: '',
            loading: false,
            tires: [],
            currentTire: {
                id: null,
                fire_number: null,
            },
            movements: [],
            movementsLength: 0,
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                options: {
                    customer_id: null,
                    search: '',
                },
                loading: true,
                headers: [
                    {text: 'Empresa', value: 'customer.business_name'},
                    {text: 'N de fuego', value: 'fire_number'},
                    {text: 'Marca', value: 'brand.name'},
                    {text: 'Diseño', value: 'design.name'},
                    {text: 'Medida', value: 'measure.name'},
                    {text: 'Costo', value: 'cost'},
                    {text: 'OTD', value: 'otd'},
                    {text: 'Estado', value: 'status_nice'},
                    {text: 'Reencauches', value: 'retread'},
                    {text: 'Acciones', value: 'actions', sortable: false},
                ],
                title: 'Movimientos',
            },
            brands: [],
            designs: [],
            currentTireForRetread: {},
            retread_form: {
                brand_id: null,
                design_id: null,
                cost: null,
            },
            sendingRetread: false,
            firstLoading: true,
            customers: [{
                business_name: 'Todos',
                id: null
            }],
            user: null,
            isAdmin: false,
        }),
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
            if (this.isAdmin) {
                // this.isAdmin = true;
                customerService.all().then(response => {
                    this.customers = response.data.data;
                    this.customers.unshift({
                        business_name: 'Todos',
                        id: null
                    });
                });
            }
            this.getDataFromApi();
            tireBrandService.all(1).then(response => {
                this.brands = response.data.data;
            });
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                tireService.datatable(this.options.options).then(response => {
                    this.tires = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            selectCustomer() {
                this.getDataFromApi();
            },
            showMovements(id) {
                this.overlay = true;
                tireService.movements(id).then(response => {
                    this.overlay = false;
                    this.currentTire = response.data.data;
                    this.movements = this.currentTire.movements;
                    this.movementsLength = this.movements.length;

                    this.scrollToElement();
                }).catch(error => {
                    this.overlay = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            },
            retread(item) {
                this.currentTireForRetread = item;
                this.dialog = true;
            },
            getByBrand() {
                tireDesignService.allByBrand(this.retread_form.brand_id).then(response => {
                    this.designs = response.data.data;
                });
            },
            sendRetread() {
                if (!this.retread_form.brand_id || !this.retread_form.design_id || !this.retread_form.cost) {
                    this.textError = 'Complete todos los campos';
                    this.snackbar = true;
                    return;
                }

                this.sendingRetread = true;
                tireService.retread(this.currentTireForRetread.id, this.retread_form).then(response => {
                    this.sendingRetread = false;
                    this.getDataFromApi();
                    this.cancelRetread();
                }).catch(error => {
                    this.sendingRetread = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
            cancelRetread() {
                this.retread_form = {
                    brand_id: null,
                    design_id: null,
                    cost: null,
                };
                this.dialog = false;
            },
            scrollToElement() {
                const el = this.$refs.containerMovements;
                if (el) {
                    // Use el.scrollIntoView() to instantly scroll to the element
                    el.scrollIntoView({behavior: 'smooth'});
                }
            },
            itemRowBackground(item) {
                return item.id == this.currentTire.id ? 'row-selected' : '';
            }
        }
    };
</script>

<style>
    .row-selected {
        background-color: rgba(0, 0, 0, 0.3);
    }
</style>
