<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Editar Diseño y Medida</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <DesignMeasurementForm :designMeasurement="designMeasurement" v-if="designMeasurement.id"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import DesignMeasurementForm from '@/components/tires/designMeasurement/DesignMeasurementForm'
    import designMeasurementService from '@/services/tires/designMeasurementService';
    import tireDesignService from '@/services/tires/tireDesignService';

    export default {
        name: "DesignMeasurementEdit",
        components: {
            Breadcrumbs,
            DesignMeasurementForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Diseño y Medidas', disabled: false, to: {name: 'DesignMeasurement'}, exact: true},
                {text: 'Editar Diseño y Medida', disabled: true, to: {name: 'DesignMeasurementEdit'}, exact: true}
            ],
            designMeasurement: {
                id: null,
                brand_id: null,
                design_id: null,
                measure_id: null,
                origin_id: null,
                otd: null,
                overall_diameter: null,
                total_width: null,
                max_speed: null,
                simple_load_index: null,
                dual_load_index: null,
                simple_load_limit: null,
                dual_load_limit: null,
                pr: null,
            },
            designs: []
        }),
        mounted() {
            this.getTireDesignById(this.$route.params.id);
        },
        methods: {
            getTireDesignById(id) {
                designMeasurementService.show(id).then(response => {
                    this.designMeasurement = response.data.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
