<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Editar Nivel de Desgaste</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <WearlevelForm :wearlevel="wearlevel"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import WearlevelForm from '@/components/tires/wearlevel/WearlevelForm';
    import tireWearlevelService from '@/services/tires/tireWearlevelService';

    export default {
        name: 'WearlevelEdit',
        components: {
            Breadcrumbs,
            WearlevelForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Nivel de Desgastes', disabled: false, to: {name: 'TiresWearlevel'}, exact: true},
                {text: 'Editar Nivel de Desgaste', disabled: true, to: {name: 'TiresWearlevelEdit'}, exact: true},
            ],
            wearlevel: {
                id: null,
                name: '',
                status: null,
                status_nice: 'Active',
                photo: '',
                photo_url: ''
            },
        }),
        created() {
            this.getTireWearlevelById(this.$route.params.id);
        },
        methods: {
            getTireWearlevelById(id) {
                tireWearlevelService.show(id).then(response => {
                    this.wearlevel = response.data.data;
                });
            }
        }
    }
</script>

<style scoped>

</style>
