<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Agregar Nuevo Combustible</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UnitFuelForm :unitfuel="unitfuel"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitFuelForm from '@/components/units/fuel/UnitFuelForm'

    export default {
        name: 'UnitFuelAdd',
        components: {
            Breadcrumbs,
            UnitFuelForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Combustibles', disabled: false, to: {name: 'UnitFuel'}, exact: true},
                {text: 'Crear Combustible', disabled: true, to: {name: 'UnitFuelAdd'}, exact: true},
            ],
            unitfuel: {
                id: null,
                name: '',
                status: 1,
                status_nice: 'Active'
            }

        })
    }
</script>

<style scoped>

</style>
