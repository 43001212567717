import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/customers/datatable`, data);
    },

    userDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/customers/users/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/customers`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/customers/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/customers/${id}`);
    },

    showUser(id) {
        return axios.get(`${ENDPOINT_PATH}/customers/users/show/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/customers`, data);
    },

    storeUser(data) {
        return axios.post(`${ENDPOINT_PATH}/customers/users/store`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/customers/${data.id}`, data);
    },

    updateUser(data) {
        return axios.put(`${ENDPOINT_PATH}/customers/users/update/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/customers/${id}`);
    },
}
