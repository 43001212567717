<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Editar Cliente</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <CustomerForm :customer="customer"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import CustomerForm from '@/components/administrations/customer/CustomerForm';
    import customerService from '@/services/administrations/customerService';

    export default {
        name: "CustomerEdit",
        components: {
            Breadcrumbs,
            CustomerForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Clientes', disabled: false, to: {name: 'Customer'}, exact: true},
                {text: 'Editar Cliente', disabled: true, to: {name: 'CustomerEdit'}, exact: true}
            ],
            customer: {
                business_name: '',
                ruc: '',
                address: '',
                email: '',
                contact_person: '',
                maintenance_manager: '',
                maintenance_chief: '',
                minor_rtd: null,
                major_rtd: null,
                status: null,
            }
        }),
        created() {
            this.getById(this.$route.params.id);
        },
        methods: {
            getById(id) {
                customerService.show(id).then(response => {
                    this.customer = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
