import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/tires/datatable`, data);
    },

    all(status = null, customer_id = null) {
        // let url = `${ENDPOINT_PATH}/tires?`;
        /*if (status != null) {
            url += 'status=' + status;
        }*/
        /*if (status != null) {
            url += 'status=' + status;
        }*/
        // return axios.get(url);
        return axios.get(`${ENDPOINT_PATH}/tires`, {
            params: {
                status: status != null ? status : '',
                customer_id: customer_id != null ? customer_id : ''
            }
        });
    },

    exportExcel(form) {
        return axios.get(`${ENDPOINT_PATH}/tires/export-list`, {
            responseType: 'blob',
            params: form
        });
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/tires/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/tires`, data);
    },

    bulkLoad(data) {
        return axios.post(`${ENDPOINT_PATH}/tires/bulk-load`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/tires/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/tires/${id}`);
    },

    movements(id) {
        return axios.get(`${ENDPOINT_PATH}/tires/movements/${id}`);
    },

    retread(id, form) {
        return axios.patch(`${ENDPOINT_PATH}/tires/retread/${id}`, form);
    },

    travelExport(form) {
        return axios.get(`${ENDPOINT_PATH}/tires/travel/export`, {
            params: form,
            responseType: 'blob'
        });
    },

    finalsExport(form) {
        return axios.get(`${ENDPOINT_PATH}/tires/finals/export`, {
            params: form,
            responseType: 'blob'
        });
    },

    scrapExport(form) {
        return axios.post(`${ENDPOINT_PATH}/tires/scrap/export`, form,{
            responseType: 'blob'
        });
    },

}
