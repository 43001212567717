<template>
  <div>
    <h1>Mantenimiento</h1>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
  components: {},
};
</script>

<style scoped></style>
