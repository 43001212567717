import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/mobile-apps/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/mobile-apps`);
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/mobile-apps/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/mobile-apps`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/mobile-apps/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/mobile-apps/${id}`);
    },

    getLastVersion() {
        return axios.get(`${ENDPOINT_PATH}/mobile-apps/last-version`);
    }
}
