<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" @submit.prevent="submit">
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <v-select
                                v-if="showCustomerId"
                                v-model="fuel.customer_id"
                                :items="customers"
                                :rules="customerIdRules"
                                outlined
                                item-text="business_name"
                                item-value="id"
                                label="Cliente"
                        ></v-select>

                        <v-autocomplete
                                v-model="fuel.vehicle"
                                :items="vehicles"
                                :loading="isLoadingSearch"
                                :rules="rules.tire_template"
                                :search-input.sync="search"
                                hide-no-data
                                hide-selected
                                item-text="license_plate"
                                item-value="id"
                                label="Vehículos"
                                placeholder="Busca un Vehículo"
                                prepend-icon="mdi-database-search"
                                return-object
                                @change='onSelectTemplate'
                        ></v-autocomplete>

                        <v-menu v-model="fromDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        label="Fecha"
                                        prepend-icon="event"
                                        readonly
                                        :value="date_value"
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    locale="es-ES"
                                    v-model="fuel.date"
                                    no-title
                                    @input="fromDateMenu = false"
                            ></v-date-picker>
                        </v-menu>
                        <v-text-field
                                label="Odómetro"
                                v-model="fuel.km_travel"
                                required
                                type="number"
                                outlined
                        ></v-text-field>
                        <v-text-field
                                label="Horas Recorridas"
                                v-model="fuel.time_travel"
                                required
                                type="number"
                                outlined
                        ></v-text-field>
                        <v-select
                                v-model="fuel.fuel_id"
                                :rules="rules.fuel_id"
                                :items="fuels"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Combustible"
                        ></v-select>
                        <v-text-field
                                label="Cant. Combustible"
                                :rules="rules.fuel_value"
                                v-model="fuel.fuel_value"
                                type="number"
                                required
                                outlined
                        ></v-text-field>
                        <v-text-field
                                label="Costo"
                                :rules="rules.cost"
                                v-model="fuel.cost"
                                type="number"
                                required
                                outlined
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="!fuel.id" :loading="loading">
                                Guardar
                            </v-btn>
                        </div>
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="fuel.id" :loading="loading">
                                Actualizar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>

</template>

<script>
    import vehicleFuelService from '@/services/vehicleFuelService'
    import unitService from "@/services/units/unitService";
    import unitFuelService from "@/services/units/unitFuelService";
    import customerService from '@/services/administrations/customerService';

    export default {
        props: ["fuel", "vehiclesParam"],
        nama: 'FuelForm',
        components: {},
        data: () => ({
            fromDateMenu: false,
            date: null,
            menu: false,
            snackbar: false,
            textError: '',
            loading: false,
            valid: false,
            search: null,
            rules: {
                km_travel: [
                    v => !!v || 'Valor del odómetro',
                ],
                fuel_id: [
                    v => !!v || 'Seleccionar un combustible',
                ],
                fuel_value: [
                    v => !!v || 'Ingresa la cantidad de combustible',
                ],
                cost: [
                    v => !!v || 'Ingrese el costo',
                ],
            },
            customerIdRules: [
                v => !!v || 'Cliente es requerido',
            ],
            customers: [],
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 2, name: 'Inactivo'}
            ],
            fuels: [],
            vehicles: [],
            isLoadingSearch: false,
            showCustomerId: false,
        }),
        computed: {
            date_value() {
                return this.formatDate(this.fuel.date);
            }
        },
        watch: {
            search(val) {
                // Items have already been requested
                if (this.isLoadingSearch) return;

                this.isLoadingSearch = true;
                // Lazily load input items
                unitService.search(val, 0, this.fuel.customer_id).then(response => {
                    this.vehicles = response.data.data;
                    this.isLoadingSearch = false;
                }).catch(error => {
                    this.isLoadingSearch = false;
                    this.loading = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },

            menu(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
        },
        mounted() {
            if (this.vehiclesParam) {
                this.vehicles.push(this.vehiclesParam);
            }
            this.getFuels();

            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
            if (this.fuel.id !== 0 && this.isAdmin) {
                this.showCustomerId = true;
                customerService.all().then(response => {
                    this.customers = response.data.data;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            }
        },
        methods: {
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
            getFuels() {
                unitFuelService.all().then(response => {
                    this.fuels = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            },
            onSelectTemplate(e) {
                this.fuel.vehicle_id = e.id;
            },
            submit() {
                if (!this.fuel.time_travel && !this.fuel.km_travel) {
                    this.textError = 'Llene el campo de Odómetro Horas Recorridas';
                    this.snackbar = true;
                    return;
                }

                this.loading = true;
                if (this.fuel.id) {
                    vehicleFuelService.update(this.fuel).then(response => {
                        this.$router.push({path: '/process/fuel'});
                    }, error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                } else {
                    vehicleFuelService.store(this.fuel).then(response => {
                        this.$router.push({path: '/process/fuel'});
                    }, error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                }
            },
        }
    }
</script>

<style type="text/css">


</style>
