<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
          <v-container>
             <v-row>
                <v-col cols="12" sm="6" d="8" >
                    <h1>Editar Documento</h1>
                </v-col>
            </v-row>
             <v-row align="center" justify="center">
                 <v-card width='90%'>
                        <OtherDocumentForm :document="document"/>
                 </v-card>
             </v-row>
          </v-container>

    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import OtherDocumentForm from '@/components/others/document/OtherDocumentForm';
    import otherDocumentService from '@/services/others/otherDocumentService';

    export default {
        name:'OtherDocumentEdit',
        components:{
            Breadcrumbs,
            OtherDocumentForm
        },
        data:()=>({
            breadcrumbs: [
                {text: 'Documentos', disabled: false, to: {name:'OtherDocument'}, exact: true},
                {text: 'Editar Documento', disabled: true,to: {name:'OtherDocumentEdit'}, exact: true}
            ],
            document:{
                id: null,
                name:'',
                status:null,
                status_nice:'',
            },
        }),
        created (){
            this.getOtherDocumentById(this.$route.params.id);
        },
        methods: {
            getOtherDocumentById(id){
                otherDocumentService.show(id).then(response => {
                    this.document = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
