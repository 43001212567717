<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>

        <Breadcrumbs :items="breadcrumbs"/>
        <v-container fluid v-if="unit">
            <UnitTires2D :unit="unit" v-if="unit.unit_type_axis.name === '2D'"></UnitTires2D>
            <UnitTires2D2 :unit="unit" v-if="unit.unit_type_axis.name === '2D2'"></UnitTires2D2>
            <UnitTires2DD :unit="unit" v-if="unit.unit_type_axis.name === '2DD'"></UnitTires2DD>
            <UnitTires2DDWB :unit="unit" v-if="unit.unit_type_axis.name === '2DDWB'"></UnitTires2DDWB>
            <UnitTires4x2 :unit="unit" v-if="unit.unit_type_axis.name === '4x2'"></UnitTires4x2>
            <UnitTires4x4 :unit="unit" v-if="unit.unit_type_axis.name === '4x4'"></UnitTires4x4>
            <UnitTires22D :unit="unit" v-if="unit.unit_type_axis.name === '22D'"></UnitTires22D>
            <UnitTires22D2 :unit="unit" v-if="unit.unit_type_axis.name === '22D2'"></UnitTires22D2>
            <UnitTires22DD :unit="unit" v-if="unit.unit_type_axis.name === '22DD'"></UnitTires22DD>
            <UnitTires22DDD :unit="unit" v-if="unit.unit_type_axis.name === '22DDD'"></UnitTires22DDD>
            <UnitTiresS4 :unit="unit" v-if="unit.unit_type_axis.name === 'S4'"></UnitTiresS4>
            <UnitTiresS4WB :unit="unit" v-if="unit.unit_type_axis.name === 'S4WB'"></UnitTiresS4WB>
            <UnitTiresS6WB :unit="unit" v-if="unit.unit_type_axis.name === 'S6WB'"></UnitTiresS6WB>
            <UnitTiresS8 :unit="unit" v-if="unit.unit_type_axis.name === 'S8'"></UnitTiresS8>
            <UnitTiresS12 :unit="unit" v-if="unit.unit_type_axis.name === 'S12'"></UnitTiresS12>
            <UnitTiresS16 :unit="unit" v-if="unit.unit_type_axis.name === 'S16'"></UnitTiresS16>
            <UnitTiresS162 :unit="unit" v-if="unit.unit_type_axis.name === 'S16-2'"></UnitTiresS162>
            <UnitTiresS243 :unit="unit" v-if="unit.unit_type_axis.name === 'S24-3'"></UnitTiresS243>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitTires2D from '@/views/units/unit-tires/UnitTires2D';
    import UnitTires2D2 from '@/views/units/unit-tires/UnitTires2D2';
    import UnitTires2DD from '@/views/units/unit-tires/UnitTires2DD';
    import UnitTires2DDWB from '@/views/units/unit-tires/UnitTires2DDWB';
    import UnitTires4x2 from '@/views/units/unit-tires/UnitTires4x2';
    import UnitTires4x4 from '@/views/units/unit-tires/UnitTires4x4';
    import UnitTires22D from '@/views/units/unit-tires/UnitTires22D';
    import UnitTires22D2 from '@/views/units/unit-tires/UnitTires22D2';
    import UnitTires22DD from '@/views/units/unit-tires/UnitTires22DD';
    import UnitTires22DDD from '@/views/units/unit-tires/UnitTires22DDD';
    import UnitTiresS4 from '@/views/units/unit-tires/UnitTiresS4';
    import UnitTiresS4WB from '@/views/units/unit-tires/UnitTiresS4WB';
    import UnitTiresS6WB from '@/views/units/unit-tires/UnitTiresS6WB';
    import UnitTiresS8 from '@/views/units/unit-tires/UnitTiresS8';
    import UnitTiresS12 from '@/views/units/unit-tires/UnitTiresS12';
    import UnitTiresS16 from '@/views/units/unit-tires/UnitTiresS16';
    import UnitTiresS162 from '@/views/units/unit-tires/UnitTiresS16-2';
    import UnitTiresS243 from '@/views/units/unit-tires/UnitTiresS24-3';
    import unitService from '@/services/units/unitService';

    export default {
        name: "UnitTires",
        components: {
            Breadcrumbs,
            UnitTires2D,
            UnitTires2D2,
            UnitTires2DD,
            UnitTires2DDWB,
            UnitTires4x2,
            UnitTires4x4,
            UnitTires22D,
            UnitTires22D2,
            UnitTires22DD,
            UnitTires22DDD,
            UnitTiresS4,
            UnitTiresS4WB,
            UnitTiresS6WB,
            UnitTiresS8,
            UnitTiresS12,
            UnitTiresS16,
            UnitTiresS162,
            UnitTiresS243,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Unidades', disabled: false, to: {name: 'Units'}, exact: true},
                {text: 'Asignar Neumáticos', disabled: true, to: {name: 'UnitTires'}, exact: true},
            ],
            snackbar: false,
            textError: '',
            unit: null,
        }),
        mounted() {
            this.getById(this.$route.params.id);
        },
        methods: {
            getById(id) {
                unitService.show(id).then(response => {
                    this.unit = response.data.data;
                });
            },
        }
    }
</script>

<style scoped>

</style>
