<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" @submit.prevent="submit">
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="customer.business_name"
                                :rules="rules.business_name"
                                label="Razón Social"
                                required
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="customer.ruc"
                                :rules="rules.ruc"
                                label="R.U.C."
                                v-intNumber
                                required
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="customer.address"
                                :rules="rules.address"
                                label="Dirección"
                                required
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="customer.email"
                                label="Correo"
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="customer.contact_person"
                                label="Persona de Contacto"
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="customer.maintenance_manager"
                                label="Gerente de Mantenimiento"
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="customer.maintenance_chief"
                                label="Jefe de Mantenimiento"
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="customer.minor_rtd"
                                :rules="rules.minor_rtd"
                                outlined
                                type="number"
                                label="RTD Menor"
                                required
                        ></v-text-field>

                        <v-text-field
                                v-model="customer.major_rtd"
                                :rules="rules.major_rtd"
                                outlined
                                type="number"
                                label="RTD Mayor"
                                required
                        ></v-text-field>

                        <v-select
                                v-model="customer.status"
                                :items="allstatus"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Estado"
                        ></v-select>

                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn :disabled="!valid || loading" type="submit" v-if="!customer.id" :loading="loading">
                                Guardar
                            </v-btn>
                        </div>
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn :disabled="!valid || loading" type="submit" v-if="customer.id" :loading="loading">
                                Actualizar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import customerService from '@/services/administrations/customerService'

    export default {
        name: "CustomerForm",
        props: ["customer"],
        components: {},
        data: () => ({
            loading: false,
            valid: false,
            rules: {
                business_name: [
                    v => !!v || 'Nombre es requerido',
                ],
                ruc: [
                    v => !!v || 'R.U.C. es requerido',
                ],
                address: [
                    v => !!v || 'Dirección es requerido',
                ],
                minor_rtd: [
                    v => !!v || 'RTD Menor requerido',
                ],
                major_rtd: [
                    v => !!v || 'RTD Mayor requerido',
                ],
            },
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ],
            snackbar: false,
            textError: '',
        }),
        methods: {
            submit() {
                this.loading = true;
                if (this.customer.id) {
                    customerService.update(this.customer).then(response => {
                        this.$router.push({path: '/administration/customers'})
                    }, error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                        console.log('error', error);
                    });
                } else {
                    customerService.store(this.customer).then(response => {
                        this.$router.push({path: '/administration/customers'})
                    }, error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                        console.error('error', error);
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>
