<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Editar Marca</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UnitBrandForm :unitbrand="unitbrand"/>
                </v-card>
            </v-row>
        </v-container>

    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitBrandForm from '@/components/units/brand/UnitBrandForm';
    import unitBrandService from '@/services/units/unitBrandService';

    export default {
        name: 'BrandEdit',
        components: {
            Breadcrumbs,
            UnitBrandForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Marcas', disabled: false, to: {name: 'UnitBrand'}, exact: true},
                {text: 'Editar Marca', disabled: true, to: {name: 'UnitBrandEdit'}, exact: true}
            ],
            unitbrand: {
                id: null,
                name: '',
                status: null,
                status_nice: '',
            },
        }),
        created() {
            this.getUnitBrandById(this.$route.params.id);
        },
        methods: {
            getUnitBrandById(id) {
                unitBrandService.show(id).then(response => {
                    this.unitbrand = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
