<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Agregar Operación</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <FleetForm :fleet="fleet"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import FleetForm from '@/components/fleets/FleetForm';
    import fleetService from '@/services/fleetService'

    export default {
        name: "FleetEdit",
        components: {
            Breadcrumbs,
            FleetForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Operación', disabled: false, to: {name: 'Fleets'}, exact: true},
                {text: 'Editar Operación', disabled: true, to: {name: 'FleetEdit'}, exact: true},
            ],
            fleet: {
                id: 0,
                customer_id: '',
                name: '',
                status: null,
            }
        }),
        mounted() {
            this.getById(this.$route.params.id);
        },
        methods: {
            getById(id) {
                fleetService.show(id).then(response => {
                    this.fleet = response.data.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
