<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">Cerrar</v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" @submit.prevent="submit">
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="8">
                        <fieldset>
                            <legend>Datos Generales</legend>
                            <v-select
                                    v-model="designMeasurement.brand_id"
                                    :items="brands"
                                    :rules="rules.brand_id"
                                    v-on:change="getByBrand"
                                    outlined
                                    item-text="name"
                                    item-value="id"
                                    label="Marca"
                            ></v-select>
                            <v-select
                                    v-model="designMeasurement.design_id"
                                    :items="designs"
                                    :rules="rules.design_id"
                                    outlined
                                    item-text="name"
                                    item-value="id"
                                    label="Diseño"
                            ></v-select>
                            <v-select
                                    v-model="designMeasurement.measure_id"
                                    :items="measures"
                                    :rules="rules.measure_id"
                                    outlined
                                    item-text="name"
                                    item-value="id"
                                    label="Medida"
                            ></v-select>
                            <v-select
                                    v-model="designMeasurement.origin_id"
                                    :items="origins"
                                    :rules="rules.origin_id"
                                    outlined
                                    item-text="name"
                                    item-value="id"
                                    label="Procedencia"
                            ></v-select>
                        </fieldset>

                        <fieldset>
                            <legend>Especificaciones Técnicas</legend>
                            <v-text-field
                                    v-model="designMeasurement.otd"
                                    :rules="rules.otd"
                                    label="OTD mm."
                                    type="number"
                                    required
                                    outlined
                            ></v-text-field>

                            <v-text-field
                                    v-model="designMeasurement.overall_diameter"
                                    :rules="rules.overall_diameter"
                                    label="Diámetro total mm."
                                    type="number"
                                    required
                                    outlined
                            ></v-text-field>

                            <v-text-field
                                    v-model="designMeasurement.total_width"
                                    :rules="rules.total_width"
                                    label="Ancho total mm."
                                    type="number"
                                    required
                                    outlined
                            ></v-text-field>

                            <v-text-field
                                    v-model="designMeasurement.max_speed"
                                    :rules="rules.max_speed"
                                    label="Velocidad Máxima Km/h"
                                    type="number"
                                    required
                                    outlined
                            ></v-text-field>

                        </fieldset>

                        <fieldset>
                            <legend>Carga</legend>
                            <v-text-field
                                    v-model="designMeasurement.simple_load_index"
                                    label="Índice de Carga Simple"
                                    v-intNumber
                                    required
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    v-model="designMeasurement.dual_load_index"
                                    label="Índice de Carga Dual"
                                    v-intNumber
                                    required
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    v-model="designMeasurement.simple_load_limit"
                                    label="Límite de Carga Simple Kg/PSI"
                                    required
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    v-model="designMeasurement.dual_load_limit"
                                    label="Límite de Carga Dual Kg/PSI"
                                    required
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    v-model="designMeasurement.pr"
                                    :rules="rules.pr"
                                    label="PR"
                                    v-intNumber
                                    required
                                    outlined
                            ></v-text-field>
                        </fieldset>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="!designMeasurement.id" :loading="loading">
                                Guardar
                            </v-btn>
                        </div>
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="designMeasurement.id" :loading="loading">
                                Actualizar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import designMeasurementService from '@/services/tires/designMeasurementService';
    import tireBrandService from '@/services/tires/tireBrandService';
    import tireDesignService from '@/services/tires/tireDesignService';
    import tireMeasureService from '@/services/tires/tireMeasureService';
    import tireOriginService from '@/services/tires/tireOriginService';

    export default {
        name: "DesignMeasurementForm",
        props: ["designMeasurement"],
        components: {},
        data: () => ({
            snackbar: false,
            textError: '',
            loading: false,
            valid: false,
            brands: [],
            designs: [],
            measures: [],
            origins: [],
            rules: {
                brand_id: [
                    v => !!v || 'Marca es requerida',
                ],
                design_id: [
                    v => !!v || 'Diseño es requerido',
                ],
                measure_id: [
                    v => !!v || 'Medida es requerido',
                ],
                origin_id: [
                    v => !!v || 'Procedencia es requerida',
                ],
                otd: [
                    v => !!v || 'OTD es requerido',
                ],
                overall_diameter: [
                    v => !!v || 'Diámetro total es requerido',
                ],
                total_width: [
                    v => !!v || 'Ancho total es requerido',
                ],
                max_speed: [
                    v => !!v || 'Velocidad máxima es requerida',
                ],
                /*simple_load_index: [
                    v => !!v || 'Índice de carga simple es requerida',
                ],
                dual_load_index: [
                    v => !!v || 'Índice de carga dual es requerida',
                ],
                simple_load_limit: [
                    v => !!v || 'Límite de carga simple es requerida',
                ],
                dual_load_limit: [
                    v => !!v || 'Límite de carga dual es requerida',
                ],
                pr: [
                    v => !!v || 'PR es requerida',
                ],*/
            },
            designMeasurements: []
        }),
        mounted() {
            if(this.designMeasurement.id) {
                this.getByBrand();
                // this.designs = this.designsParam;
            }

            tireBrandService.all().then(response => {
                this.brands = response.data.data;
            }).catch(error => {
                console.error(error);
            });
           /* tireDesignService.all().then(response => {
                this.designs = response.data.data;
            }).catch(error => {
                console.error(error);
            });*/
            tireMeasureService.all().then(response => {
                this.measures = response.data.data;
            }).catch(error => {
                console.error(error);
            });
            tireOriginService.all().then(response => {
                this.origins = response.data.data;
            }).catch(error => {
                console.error(error);
            });
        },
        methods: {
            getByBrand() {
                tireDesignService.allByBrand(this.designMeasurement.brand_id).then(response => {
                    this.designs = response.data.data;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
            submit() {
                this.loading = true;
                if (this.designMeasurement.id) {
                    designMeasurementService.update(this.designMeasurement).then(response => {
                        this.$router.push({path: '/tires/design-measurements'})
                    }, error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                } else {
                    designMeasurementService.store(this.designMeasurement).then(response => {
                        this.$router.push({path: '/tires/design-measurements'})
                    }, error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                }
            },
        }
    }
</script>

<style scoped>
    fieldset {
        padding: 1rem;
        margin-bottom: 2rem;
    }
</style>
