<template>
    <div class="content">
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <div class="content-image"></div>
        <div class="content-form">
            <v-container grid-list-md text-xs-center class="form-container">
                <v-card align="center" justify="center">
                    <img src="../../assets/images/logopts.png" class="center"/>
                    <v-card-title class="justify-center">Inicia Sesión</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="submit">
                            <v-container>
                                <v-row>
                                    <v-text-field
                                            v-model="email"
                                            :error-messages="emailErrors"
                                            label="Email"
                                            type="email"
                                            outlined
                                            required
                                            @input="$v.email.$touch()"
                                            @blur="$v.email.$touch()"
                                    ></v-text-field>
                                </v-row>
                                <v-row>
                                    <v-text-field
                                            v-model="password"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :error-messages="passwordErrors"
                                            :type="show1 ? 'text' : 'password'"
                                            label="Contraseña"
                                            name="input-10-1"
                                            outlined
                                            required
                                            @click:append="show1 = !show1"
                                    ></v-text-field>
                                </v-row>

                                <v-row class="justify-center">
                                    <p class="forgot">
                                        <router-link to="/recover-password">Olvidé mi contraseña</router-link>
                                    </p>
                                </v-row>

                                <v-row class="justify-center button-submit login-submit">
                                    <v-btn block="block" type="submit"
                                           :loading="loading"
                                           :disabled="loading"
                                    >Inicia sesión
                                    </v-btn>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-container>
        </div>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],
        name: "Login",
        validations: {
            email: {required},
            password: {required},
        },
        components: {},
        data: () => ({
            snackbar: false,
            textError: '',
            loading: false,
            email: "",
            password: "",
            show1: false,
            rules: {
                required: value => !!value || 'Requerido',
            },
        }),
        computed: {
            emailErrors() {
                const errors = []
                if (!this.$v.email.$dirty) return errors
                !this.$v.email.required && errors.push('Email es requerido')
                return errors
            },
            passwordErrors() {
                const errors = []
                if (!this.$v.password.$dirty) return errors
                !this.$v.password.required && errors.push('Password es requerido')
                return errors
            },
        },
        methods: {
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    //this.submitStatus = 'ERROR'
                } else {
                    this.loading = true;
                    this.$store.dispatch('login', {email: this.email, password: this.password}).then(() => {
                        this.$router.push('/')
                    }).catch(error => {
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                        this.loading = false;
                    })
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .v-card {
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 3px 6px #2960c71a !important;
        border-radius: 5px !important;
        opacity: 1 !important;
        height: 80vh;
    }


</style>

<style type="text/css">
    .content {
        display: flex;
        flex-direction: row;
        height: calc(100vh - 3px);
        margin: 0;
        padding: 0;
        top: 0;
        bottom: 0;
    }

    .content-image {
        width: 70%;
        float: left;
        background-image: url("../../assets/images/pts-bg.png");
        /*background-size: 645px 638px;*/
        background-size: cover;
        /* background: transparent url('../../assets/images/nosotros.jpg') 0% 0% no-repeat padding-box; */
        /* background-image: ; */
    }

    .form-container {
        /*width: 65% !important;*/
    }

    .content-form {
        display: flex;
        align-items: center;
        width: 30%;
        background: var(--unnamed-color-f8f9fa) 0% 0% no-repeat padding-box;
        background-color: #f8f9fa 0% 0% no-repeat padding-box !important;
        float: right;
    }

    .v-card__title {
        text-align: center;
        font: normal normal bold 35px/40px Roboto !important;
        letter-spacing: 0px !important;
        color: #10131a !important;
        opacity: 1 !important;
    }

    .forgot a {
        color: var(--unnamed-color-10131a);
        text-align: center;
        font: normal normal bold 14px/19px Roboto;
        letter-spacing: 0px;
        color: #10131a;
        opacity: 0.5;
    }


    img {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .login-submit .v-btn {
        height: 55px !important;
    }

    .login-submit {
        width: 204px !important;

    }

</style>
