<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Agregar Vehículo</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UnitForm :unit="unit"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitForm from '@/components/units/unit/UnitForm';

    export default {
        name: "UnitAdd",
        components: {
            Breadcrumbs,
            UnitForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Unidades', disabled: false, to: {name: 'Units'}, exact: true},
                {text: 'Crear Unidad', disabled: true, to: {name: 'UnitAdd'}, exact: true},
            ],
            unit: {
                customer_id: null,
                license_plate: null,
                vehicle_number: null,
                brand_of_unit_id: null,
                unit_model_id: null,
                unit_type_id: null,
                unit_type_axis_id: null,
                fleet_id: null,
                flats: null,
                trip_counter: 1,
            }
        })
    }
</script>

<style scoped>

</style>
