<template>
    <div>
        <div class="div-eje" v-bind:style="{ backgroundImage: 'url(' + currentVehicle.unit_type_axis.photo_url + ')' }">
            <div class="tire1empty">
                <v-text-field
                        type="number"
                        class="tire1"
                        v-model="axises[0].weight_left"
                        v-on:input="emitPesaje"
                        outlined
                        required
                ></v-text-field>
            </div>
            <div class="tire2empty">
                <v-text-field
                        type="number"
                        class="tire1"
                        v-model="axises[0].weight_right"
                        v-on:input="emitPesaje"
                        outlined
                        required
                ></v-text-field>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PesajeS4",
        props: ['currentVehicle'],
        data: () => ({
            axises: [
                {
                    axis_number: 1,
                    weight_left: null,
                    weight_right: null,
                    weight_total: null,
                }
            ]
        }),
        mounted() {
        },
        methods: {
            emitPesaje(e) {
                this.$emit('changePesaje', this.axises);
            }
        }
    }
</script>

<style scoped>
    .div-eje {
        width: 272px;
        height: 569px;
        background-repeat: no-repeat;
        margin: 0 auto
    }

    .tire1 {
        position: absolute;
    }

    .tire1empty {
        position: absolute;
        width: 130px;
        height: 110px;
        margin-left: -35px;
        margin-top: 459px;
    }

    .tire2empty {
        position: absolute;
        width: 130px;
        height: 110px;
        margin-left: 174px;
        margin-top: 459px;
    }
</style>
