<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" @submit.prevent="submit">
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <v-select
                                v-if="showCustomerId"
                                v-model="fleet.customer_id"
                                :items="customers"
                                :rules="customerIdRules"
                                outlined
                                item-text="business_name"
                                item-value="id"
                                label="Cliente"
                        ></v-select>

                        <v-text-field
                                v-model="fleet.name"
                                :rules="rules.name"
                                label="Operación"
                                required
                                outlined
                        ></v-text-field>

                        <v-select
                                v-model="fleet.status"
                                :items="allstatus"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Estado"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="!fleet.id" :loading="loading"
                            >
                                Guardar
                            </v-btn>
                        </div>
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="fleet.id" :loading="loading"
                            >
                                Actualizar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import fleetService from '@/services/fleetService';
    import customerService from "@/services/administrations/customerService";

    export default {
        name: "FleetForm",
        props: ["fleet"],
        components: {},
        data: () => ({
            customers: [],
            showCustomerId: false,
            snackbar: false,
            textError: '',
            loading: false,
            valid: false,
            rules: {
                id: [
                    v => !!v || 'ID es requerido',
                ],
            },
            customerIdRules: [
                v => !!v || 'Cliente es requerido',
            ],
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ]
        }),
        mounted() {
            this.getCustomers();
        },
        methods: {
            getCustomers() {
                this.user = this.$store.getters.userSession;
                this.isAdmin = true;
                this.user.roles.forEach(role => {
                    if(role.name === 'customer') {
                        this.isAdmin = false;
                    }
                });
                if (this.fleet.id !== 0 && this.isAdmin) {
                    this.showCustomerId = true;
                    customerService.all().then(response => {
                        this.customers = response.data.data;
                    }).catch(error => {
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                }
            },
            submit() {
                this.loading = true;
                if (this.fleet.id) {
                    fleetService.update(this.fleet).then(response => {
                        this.$router.push({path: '/fleets'})
                    }, error => {
                        console.log('error', error);
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                        this.loading = false;
                    });
                } else {
                    fleetService.store(this.fleet).then(response => {
                        this.$router.push({path: '/fleets'})
                    }, error => {
                        console.error('error', error);
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                        this.loading = false;
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>
