import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/fleets/datatable`, data);
    },

    all(customer_id) {
        return axios.get(`${ENDPOINT_PATH}/fleets?customer_id=${customer_id}`);
    },

    exportExcel(form) {
        return axios.get(`${ENDPOINT_PATH}/fleets/export-list`, {
            responseType: 'blob',
            params: form
        });
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/fleets/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/fleets`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/fleets/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/fleets/${id}`);
    },
}
