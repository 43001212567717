<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <Datatableindex :options="options" :data="users" :hasDeleteItem="false" :withExport="false"/>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import customerService from "@/services/administrations/customerService";

    export default {
        name: "UserCustomer",
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Usuarios por Cliente', disabled: true, to: {name: 'UserCustomer'}, exact: true}
            ],
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            users: [],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 5,
                options: {
                    search: '',
                    role: 'customer',
                },
                loading: true,
                headers: [
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    {text: 'Cliente', value: 'customer_user'},
                    {text: 'Nombre', value: 'name'},
                    {text: 'Correo', value: 'email'},
                    {text: 'Estado', value: 'status_nice'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                title: 'Usuarios por Cliente',
                titleAddButton: 'Agregar Usuario',
                addlink: '/administration/customer/user/create',
                editlink: '/administration/customer/user/edit/',
            },
            firstLoading: true,
        }),
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.getDataFromApi()
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                customerService.userDatatable(this.options.options).then(response => {
                    this.users = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                }).catch(error => {
                    console.error(error);
                });
            },
        }
    }
</script>

<style scoped>

</style>
