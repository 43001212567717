<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Agregar Neumático</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <TireForm :tire="tire"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import TireForm from '@/components/tires/TireForm';

    export default {
        name: "TireAdd",
        components: {
            Breadcrumbs,
            TireForm,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Neumáticos', disabled: false, to: {name: 'Tires'}, exact: true},
                {text: 'Crear Neumático', disabled: true, to: {name: 'TireAdd'}, exact: true},
            ],
            tire: {
                tireTemplate: null,
                tire_template_id: null,
                created_at: null,
                fire_number: null,
                cost: null,
                customer_id: null,
                brand_name: null,
                design_name: null,
                measure_name: null,
                origin_name: null,
                otd: null,
                overall_diameter: null,
                total_width: null,
                max_speed: null,
                simple_load_index: null,
                dual_load_index: null,
                simple_load_limit: null,
                dual_load_limit: null,
                pr: null,
                retread: false,
                retread_brand_id: null,
                retread_design_id: null,
                retread_cost: null,
                retread_retread: 0,
            }
        })
    }
</script>

<style scoped>

</style>
