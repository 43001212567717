import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/vehicle-fuels/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/vehicle-fuels`);
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/vehicle-fuels/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/vehicle-fuels`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/vehicle-fuels/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/vehicle-fuels/${id}`);
    },

    reportPerVehicles(form) {
        return axios.get(`${ENDPOINT_PATH}/vehicle-fuels/report/vehicle`, {
            responseType: 'blob',
            params: form
        });
    },

    reportPerTires(form) {
        return axios.get(`${ENDPOINT_PATH}/vehicle-fuels/report/tire`, {
            responseType: 'blob',
            params: form
        });
    },
}
