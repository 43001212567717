import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/tire-templates/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/tire-templates`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/tire-templates/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/tire-templates/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/tire-templates`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/tire-templates/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/tire-templates/${id}`);
    },

    search(search) {
        if (!search) {
            search = '';
        }
        return axios.get(`${ENDPOINT_PATH}/tire-templates/search?search=${search}`);
    },
}
