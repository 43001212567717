<template>
  <div>
    <h1>Reports</h1>
  </div>
</template>

<script>
export default {
  name: "Report",
  components: {},
};
</script>

<style scoped></style>
