<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-form v-model="valid" @submit.prevent="submit">
                        <v-row>
                            <v-col v-if="isAdmin" cols="12" md="4">
                                <v-select
                                        v-model="form.customer_id"
                                        :items="customers"
                                        :rules="rules.customer_id"
                                        item-text="business_name"
                                        item-value="id"
                                        label="Cliente"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-menu v-model="fromDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                label="Desde"
                                                prepend-icon="event"
                                                :rules="rules.from_date"
                                                readonly
                                                :value="from_date_value"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            locale="es-ES"
                                            v-model="form.from_date"
                                            no-title
                                            @input="fromDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-menu v-model="toDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                label="Hasta"
                                                prepend-icon="event"
                                                :rules="rules.to_date"
                                                readonly
                                                :value="to_date_value"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            locale="es-ES"
                                            v-model="form.to_date"
                                            no-title
                                            @input="toDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-select
                                        v-model="form.brand_id"
                                        :items="brands"
                                        v-on:change="getByBrand"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Marca"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select
                                        v-model="form.design_id"
                                        :items="designs"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Diseño"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select
                                        v-model="form.measure_id"
                                        :items="measures"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Medida"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="isAdmin">
                            <v-col cols="12" md="12">
                                <label>Conclusiones y Recomendaciones</label>
                                <quill-editor
                                        ref="myQuillEditor"
                                        v-model="form.conclusions"
                                        :options="editorOption"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <div class="text-center">
                                    <v-btn type="submit" :disabled="!valid || loading" :loading="loading">
                                        Exportar
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import customerService from "@/services/administrations/customerService";
    import tireBrandService from "@/services/tires/tireBrandService";
    import tireDesignService from "@/services/tires/tireDesignService";
    import tireMeasureService from "@/services/tires/tireMeasureService";
    import tireService from "@/services/tires/tireService";
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import {quillEditor} from 'vue-quill-editor';

    export default {
        name: "Scrap",
        components: {
            Breadcrumbs,
            quillEditor
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Reporte de SCRAP', disabled: true, to: {name: 'Scrap'}, exact: true}
            ],
            loading: false,
            snackbar: false,
            textError: '',
            fromDateMenu: false,
            toDateMenu: false,
            user: null,
            isAdmin: false,
            customers: [],
            form: {
                customer_id: null,
                from_date: null,
                to_date: null,
                brand_id: null,
                design_id: null,
                measure_id: null,
                conclusions: null,
            },
            valid: false,
            rules: {
                customer_id: [
                    v => !!v || 'Cliente requerido',
                ],
                from_date: [
                    v => !!v || 'Fecha Desde requerida',
                ],
                to_date: [
                    v => !!v || 'Fecha Hasta requerida',
                ],
            },
            brands: [],
            designs: [{
                id: null,
                name: 'Todos'
            }],
            measures: [{
                id: null,
                name: 'Todas'
            }],
            editorOption: {}
        }),
        computed: {
            from_date_value() {
                return this.formatDate(this.form.from_date);
            },
            to_date_value() {
                return this.formatDate(this.form.to_date);
            }
        },
        mounted() {
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
            if (this.isAdmin) {
                // this.isAdmin = true;
                customerService.all().then(response => {
                    this.customers = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }

            tireBrandService.all().then(response => {
                this.brands = response.data.data;
                this.brands.unshift({
                    name: 'Todas',
                    id: null
                });
            }).catch(error => {
                console.error(error);
            });

            tireMeasureService.all().then(response => {
                this.measures = response.data.data;
                this.measures.unshift({
                    name: 'Todas',
                    id: null
                });
            }).catch(error => {
                console.error(error);
            });
        },
        methods: {
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
            getByBrand() {
                this.form.design_id = null;
                tireDesignService.allByBrand(this.form.brand_id).then(response => {
                    this.designs = response.data.data;
                    this.designs.unshift({
                        name: 'Todos',
                        id: null
                    });
                });
            },
            submit() {
                const form = Object.assign({}, this.form);
                form.brand_id = form.brand_id ? form.brand_id : '';
                form.design_id = form.design_id ? form.design_id : '';
                form.measure_id = form.measure_id ? form.measure_id : '';

                // if((form.brand_id_1 && !form.design_id_1) || (form.brand_id_1 && !form.measure_id_1)) {
                //     this.textError = 'Para el primer filtro de marca también debe seleccionar diseño y medida';
                //     this.snackbar = true;
                //     return;
                // }

                this.loading = true;
                tireService.scrapExport(form).then(response => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Reporte de Scrap.pdf';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.loading = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
