<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Agregar Causal de Retiro</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <CausalForm :causal="causal"/>
                </v-card>
            </v-row>
        </v-container>

    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import CausalForm from '@/components/tires/causal/CausalForm'

    export default {
        name: 'CausalAdd',
        components: {
            Breadcrumbs,
            CausalForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Causales', disabled: false, to: {name: 'TiresCausal'}, exact: true},
                {text: 'Crear Causal', disabled: true, to: {name: 'TiresCausalAdd'}, exact: true},
            ],
            causal: {
                id: null,
                name: '',
                status: 1,
                statusNice: 'Active'
            }

        })
    }
</script>

<style lang="scss" scoped>

</style>
