<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Agregar país de procedencia</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <OriginForm :origin="origin"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import OriginForm from '@/components/tires/origin/OriginForm'

    export default {
        name: 'OriginAdd',
        components: {
            Breadcrumbs,
            OriginForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Procedencias', disabled: false, to: {name: 'TiresOrigin'}, exact: true},
                {text: 'Crear Procedencia', disabled: true, to: {name: 'TiresOriginAdd'}, exact: true},
            ],
            origin: {
                id: null,
                name: '',
                status: 1,
                status_nice: 'Active'
            }

        })
    }
</script>

<style scoped>

</style>
