<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
          <v-container>
             <v-row>
                <v-col cols="12" sm="6" d="8" >
                    <h1>Editar Medida</h1>
                </v-col>
            </v-row>
             <v-row align="center" justify="center">
                 <v-card width='90%'>
                        <MeasureForm :measure="measure"/>
                 </v-card>
             </v-row>
          </v-container>

    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import MeasureForm from '@/components/tires/measure/MeasureForm';
    import tireMeasureService from '@/services/tires/tireMeasureService';

    export default {
        name:'MeasureEdit',
        components:{
            Breadcrumbs,
            MeasureForm
        },
        data:()=>({
            breadcrumbs: [
                {text: 'Medidas', disabled: false, to: {name:'TiresMeasure'}, exact: true},
                {text: 'Editar Medida', disabled: true,to: {name:'TiresMeasureEdit'}, exact: true}
            ],
            measure:{
                id: null,
                name:'',
                status:null,
                status_nice:'',
            },
        }),
        created (){
            this.getTireMeasureById(this.$route.params.id);
        },
        methods: {
            getTireMeasureById(id){
                tireMeasureService.show(id).then(response => {
                    this.measure = response.data.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
