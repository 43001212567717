<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" @submit.prevent="submit">
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <v-autocomplete
                                v-model="tire.tire_template"
                                :items="tireTemplates"
                                :loading="isLoadingSearch"
                                :rules="rules.tire_template"
                                :search-input.sync="search"
                                hide-no-data
                                hide-selected
                                item-text="code"
                                item-value="id"
                                label="Diseño y Medida"
                                placeholder="Busca un Diseño y Medida"
                                prepend-icon="mdi-database-search"
                                return-object
                                @change='onSelectTemplate'
                        ></v-autocomplete>
                        <v-select
                                v-if="showCustomerId"
                                v-model="tire.customer_id"
                                :items="customers"
                                :rules="customerIdRules"
                                outlined
                                item-text="business_name"
                                item-value="id"
                                label="Cliente"
                        ></v-select>
                        <v-menu v-model="fromDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        label="Fecha de ingreso a almacén"
                                        prepend-icon="event"
                                        outlined
                                        readonly
                                        :value="date_value"
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    locale="es-ES"
                                    v-model="tire.created_at"
                                    no-title
                                    @input="fromDateMenu = false"
                            ></v-date-picker>
                        </v-menu>
                        <v-text-field
                                v-model="tire.fire_number"
                                :rules="rules.fire_number"
                                label="Número de Fuego"
                                required
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.cost"
                                label="Costo"
                                type="number"
                                required
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.brand_name"
                                label="Marca"
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.design_name"
                                label="Diseño"
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.measure_name"
                                label="Medida"
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.origin_name"
                                label="Procedencia"
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.otd"
                                label="OTD"
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.overall_diameter"
                                label="Diámetro total mm."
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.total_width"
                                label="Ancho total mm."
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.max_speed"
                                label="Velocidad Máxima Km/h"
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.simple_load_index"
                                label="Índice de Carga Simple"
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.dual_load_index"
                                label="Índice de Carga Dual"
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.simple_load_limit"
                                label="Límite de Carga Simple Kg/PSI"
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.dual_load_limit"
                                label="Límite de Carga Dual Kg/PSI"
                                readonly
                                outlined
                        ></v-text-field>

                        <v-text-field
                                v-model="tire.pr"
                                label="PR"
                                readonly
                                outlined
                        ></v-text-field>

                        <template v-if="!tire.id">
                            <div>
                                <v-checkbox v-model="tire.retread" label="¿Ha sido reencauchada?"></v-checkbox>
                            </div>
                            <template v-if="tire.retread">
                                <v-select
                                        v-model="tire.retread_brand_id"
                                        :items="brandsRetread"
                                        v-on:change="getByBrand"
                                        :rules="rules_retread.brand_id"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Marca"
                                ></v-select>

                                <v-select
                                        v-model="tire.retread_design_id"
                                        :items="designsRetread"
                                        :rules="rules_retread.design_id"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Diseño"
                                ></v-select>

                                <v-text-field
                                        v-model="tire.retread_cost"
                                        label="Costo"
                                        type="number"
                                        required
                                        outlined
                                ></v-text-field>

                                <v-text-field
                                        v-model="tire.retread_retread"
                                        label="Reencacuches"
                                        :rules="rules_retread.retread_retread"
                                        type="number"
                                        required
                                        outlined
                                ></v-text-field>
                            </template>
                        </template>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="!tire.id" :loading="loading">
                                Guardar
                            </v-btn>
                        </div>
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="tire.id" :loading="loading">
                                Actualizar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import designMeasurementService from "@/services/tires/designMeasurementService";
    import customerService from "@/services/administrations/customerService";
    import tireService from "@/services/tires/tireService";
    import tireBrandService from '@/services/tires/tireBrandService';
    import tireDesignService from '@/services/tires/tireDesignService';

    export default {
        name: "TireForm",
        props: ["tire"],
        components: {},
        data: () => ({
            customers: [],
            showCustomerId: false,
            snackbar: false,
            textError: '',
            loading: false,
            valid: false,
            rules: {
                fire_number: [
                    v => !!v || 'Número de fuego es requerido',
                ],
                tire_template: [
                    v => !!v || 'Debe seleccionar un diseño y medida',
                ],
            },
            customerIdRules: [
                v => !!v || 'Cliente es requerido',
            ],
            rules_retread: {
                brand_id: [
                    v => !!v || 'Marca es requerida',
                ],
                design_id: [
                    v => !!v || 'Diseño es requerido',
                ],
                retread_retread: [
                    v => !!v || 'Número de reencauches es requerido',
                    v => ( v && v >= 1 ) || 'El Varlo minimo es 1',
                ],
            },
            brandsRetread: [],
            designsRetread: [],
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ],
            tireTemplates: [],
            isLoadingSearch: false,
            search: null,
            descriptionLimit: 60,
            fromDateMenu: false,
        }),
        computed: {
            date_value() {
                return this.formatDate(this.tire.created_at);
            }
        },
        watch: {
            search(val) {
                // Items have already been loaded
                // if (this.tireTemplates.length > 0) return;

                // Items have already been requested
                if (this.isLoadingSearch) return;

                this.isLoadingSearch = true;
                // Lazily load input items
                designMeasurementService.search(val).then(response => {
                    this.tireTemplates = response.data.data;
                    this.isLoadingSearch = false;
                }).catch(error => {
                    this.isLoadingSearch = false;
                    this.loading = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
            tire: function (newVal, oldVal) {
                if (newVal) {
                    this.tireTemplates.push(this.tire.tire_template);
                }
            }
        },
        mounted() {
            this.getCustomers();
            tireBrandService.all(1).then(response => {
                this.brandsRetread = response.data.data;
            }).catch(error => {
                this.isLoadingSearch = false;
                this.loading = false;
                this.textError = error.response.data.message;
                this.snackbar = true;
            });
        },
        methods: {
            getByBrand() {
                tireDesignService.allByBrand(this.tire.retread_brand_id).then(response => {
                    this.designsRetread = response.data.data;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
            getCustomers() {
                this.user = this.$store.getters.userSession;
                this.isAdmin = true;
                this.user.roles.forEach(role => {
                    if(role.name === 'customer') {
                        this.isAdmin = false;
                    }
                });
                if (this.tire.id !== 0 && this.isAdmin) {
                    this.showCustomerId = true;
                    customerService.all().then(response => {
                        this.customers = response.data.data;
                    }).catch(error => {
                        console.error(error);
                    });
                }
            },
            onSelectTemplate(e) {
                this.tire.brand_name = e.brand.name;
                this.tire.design_name = e.design.name;
                this.tire.measure_name = e.measure.name;
                this.tire.origin_name = e.origin.name;
                this.tire.otd = e.otd;
                this.tire.overall_diameter = e.overall_diameter;
                this.tire.total_width = e.total_width;
                this.tire.max_speed = e.max_speed;
                this.tire.simple_load_index = e.simple_load_index;
                this.tire.dual_load_index = e.dual_load_index;
                this.tire.simple_load_limit = e.simple_load_limit;
                this.tire.dual_load_limit = e.dual_load_limit;
                this.tire.pr = e.pr;
                this.tire.tire_template_id = e.id;
            },
            submit() {
                this.loading = true;
                if (this.tire.id) {
                    tireService.update(this.tire).then(response => {
                        this.$router.push({path: '/tires'});
                    }, error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                } else {
                    tireService.store(this.tire).then(response => {
                        this.$router.push({path: '/tires'});
                    }, error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                }
            },
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
        }
    }
</script>

<style scoped>

</style>
