<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Editar Neumático</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <TireForm :tire="tire"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import TireForm from '@/components/tires/TireForm';
    import tireService from '@/services/tires/tireService';
    import moment from 'moment';

    export default {
        name: "TireEdit",
        components: {
            Breadcrumbs,
            TireForm,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Neumáticos', disabled: false, to: {name: 'Tires'}, exact: true},
                {text: 'Crear Neumático', disabled: true, to: {name: 'TireAdd'}, exact: true},
            ],
            tire: {
                id: 0,
                tire_template: null,
                tire_template_id: null,
                fire_number: null,
                cost: null,
                customer_id: null,
                brand_name: null,
                design_name: null,
                measure_name: null,
                origin_name: null,
                otd: null,
                overall_diameter: null,
                total_width: null,
                max_speed: null,
                simple_load_index: null,
                dual_load_index: null,
                simple_load_limit: null,
                dual_load_limit: null,
                pr: null,
                retread: false,
                retread_brand_id: null,
                retread_design_id: null,
                retread_cost: null,
                retread_retread: 0,
            }
        }),
        created() {
            this.getTirePartById(this.$route.params.id);
        },
        methods: {
            getTirePartById(id) {
                tireService.show(id).then(response => {
                    this.tire = response.data.data;
                    this.tire.created_at = moment(this.tire.created_at).format('YYYY-MM-DD');
                    this.tire.brand_name = this.tire.brand.name;
                    this.tire.design_name = this.tire.design.name;
                    this.tire.measure_name = this.tire.measure.name;
                    this.tire.origin_name = this.tire.origin.name;
                })
            }
        }
    }
</script>

<style scoped>

</style>
