import axios from 'axios/index'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/weight-checks/datatable`, data);
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/weight-checks/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/weight-checks`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/weight-checks/${data.id}`, data);
    },

    delete(data) {
        return axios.delete(`${ENDPOINT_PATH}/weight-checks/${data.id}`);
    }
}
