<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Agregar Parte de Neumatico</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <PartForm :part="part"/>
                </v-card>
            </v-row>
        </v-container>

    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import PartForm from '@/components/tires/part/PartForm';

    export default {
        name: 'PartAdd',
        components: {
            Breadcrumbs,
            PartForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Partes del Neumático', disabled: false, to: {name: 'TiresPart'}, exact: true},
                {text: 'Crear Parte del Neumatico', disabled: true, to: {name: 'TiresPartAdd'}, exact: true},
            ],
            part: {
                id: null,
                name: '',
                status: 1,
                status_nice: 'Active'
            }
        })

    }
</script>

<style scoped>

</style>
