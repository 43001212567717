import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/vehicles/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/vehicles`);
    },

    exportExcel(form) {
        return axios.get(`${ENDPOINT_PATH}/vehicles/export-list`, {
            responseType: 'blob',
            params: form
        });
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/vehicles/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/vehicles`, data);
    },

    bulkLoad(data) {
        return axios.post(`${ENDPOINT_PATH}/vehicles/bulk-load`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/vehicles/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/vehicles/${id}`);
    },

    storeTires(id, data) {
        return axios.put(`${ENDPOINT_PATH}/vehicles/tires/${id}`, data);
    },

    search(search, withTires = 0, customerId = null) {
        if (!search) {
            search = '';
        }
        if (!customerId) {
            customerId = '';
        }
        return axios.get(`${ENDPOINT_PATH}/vehicles/search?search=${search}&with_tires=${withTires}&customer_id=${customerId}&items_number=20`);
    },
}
