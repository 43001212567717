<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        :color="snackbarColorBtn"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>

        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>{{options.title}}</h1>
                </v-col>
            </v-row>
            <v-row v-if="isAdmin">
                <v-col cols="12" sm="12" md="6">
                    <v-select
                            v-model="options.options.customer_id"
                            :items="customers"
                            @change="selectCustomer"
                            item-text="business_name"
                            item-value="id"
                            label="Seleccione el Cliente"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field label="Buscar..." v-model="options.options.search" solo
                                  append-icon="search"></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="3">
                    <v-btn class="button-add" @click="exportExcel">Exportar</v-btn>
                </v-col>

                <v-col cols="12" sm="12" md="3" class="button-submit d-flex">
                    <v-btn :to="options.addlink" class="button-add ml-auto">+{{options.titleAddButton}}</v-btn>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="showBulkLoad">
                    <label>Plantilla para Cargar Neumáticos</label> <br>
                    <v-btn elevation="2" :href="urlDownloadTemplate">Descargar</v-btn>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="showBulkLoad">
                    <label>Carga Masiva</label>
                    <v-file-input
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            label="Seleccione un archivo"
                            @change="handleFile($event)"
                    ></v-file-input>
                </v-col>

            </v-row>
            <v-row>
                <v-container>
                    <v-data-table
                            :page="options.page"
                            :items-per-page="options.itemsPerPage"
                            :headers="options.headers"
                            :items="tires"
                            :options.sync="options.options"
                            :loading="options.loading"
                            :server-items-length="options.itemsLength"
                            class="elevation-1">
                        <template v-slot:item.retreading="{ item }">
                            <v-simple-checkbox
                                    v-model="item.retreading"
                                    disabled
                            ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.otd="{ item }">
                            {{item.otd != null ? item.otd.toFixed(1) : item.otd}}
                        </template>
                        <template v-slot:item.cost="{ item }">
                            {{item.cost | currency}}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn icon :to="`${options.editlink}` + item.id">
                                <v-icon
                                        small
                                        class="mr-2"
                                >
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn icon>
                                <v-icon
                                        small
                                        @click="deleteItemConfirm(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>


                </v-container>
            </v-row>
        </v-container>


        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">¿Seguro de borrar este item?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" :disabled="loading" @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="error" :disabled="loading" :loading="loading" @click="deleteItemApi">
                        Sí, estoy seguro
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import tireService from "@/services/tires/tireService";
    import customerService from "@/services/administrations/customerService";
    import * as XLSX from 'xlsx';

    const ENDPOINT_PATH_PUBLIC = process.env.VUE_APP_BASE_URL_PUBLIC;

    export default {
        name: "Tires",
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Neumáticos', disabled: true, to: {name: 'Tires'}, exact: true}
            ],
            showBulkLoad: true,
            snackbarColorBtn: 'red',
            snackbar: false,
            textError: '',
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            tires: [],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                options: {
                    customer_id: null,
                    search: '',
                },
                loading: true,
                headers: [
                    {text: 'Empresa', value: 'customer.business_name'},
                    {text: 'N de fuego', value: 'fire_number'},
                    {text: 'Marca', value: 'brand.name'},
                    {text: 'Diseño', value: 'design.name'},
                    {text: 'Medida', value: 'measure.name'},
                    {text: 'Costo', value: 'cost'},
                    {text: 'OTD', value: 'otd'},
                    {text: 'Estado', value: 'status_nice'},
                    {text: 'Reencauches', value: 'retread'},
                    {text: 'Acciones', value: 'actions', sortable: false},
                ],
                title: 'Neumáticos',
                titleAddButton: 'Agregar Neumático',
                addlink: '/tires/create',
                editlink: '/tires/edit/',
            },
            customers: [{
                business_name: 'Todos',
                id: null
            }],
            user: null,
            isAdmin: false,
            urlDownloadTemplate: ENDPOINT_PATH_PUBLIC + 'templates/plantilla_carga_masiva_neumáticos.xlsx',
            overlay: false,
            firstLoading: true,
        }),
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.user = this.$store.getters.userSession;
            if (this.user.roles.find(role => role.name === 'customer')) {
                // this.showBulkLoad = true;
                this.urlDownloadTemplate = ENDPOINT_PATH_PUBLIC + 'templates/plantilla_carga_masiva_neumáticos.xlsx';
            } else {
                this.urlDownloadTemplate = ENDPOINT_PATH_PUBLIC + 'templates/plantilla_carga_masiva_neumáticos_pts.xlsx';
            }
            this.getDataFromApi();

            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if (role.name === 'customer') {
                    this.isAdmin = false;
                }
            });

            if (this.isAdmin) {
                // this.isAdmin = true;
                customerService.all().then(response => {
                    this.customers = response.data.data;
                    this.customers.unshift({
                        business_name: 'Todos',
                        id: null
                    });
                });
            }

        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                tireService.datatable(this.options.options).then(response => {
                    this.tires = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            selectCustomer() {
                this.getDataFromApi();
            },
            deleteItemConfirm(item) {
                this.dialogDelete = true;
                this.itemDelete = item;
            },
            deleteItemApi() {
                this.loading = true;
                tireService.delete(this.itemDelete.id).then(response => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.getDataFromApi();
                }).catch(error => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    console.error(error);
                });
            },
            handleFile(e) {
                if (!e) {
                    return;
                }
                const formData = new FormData();
                formData.append('file', e);
                this.overlay = true;
                tireService.bulkLoad(formData).then(response => {
                    this.overlay = false;
                    if (response.data.data.length > 0) {
                        const dataExport = [];
                        if (this.user.roles.find(role => role.name === 'customer')) {
                            dataExport.push([
                                "Marca Diseño Medida",
                                "Fecha de ingreso a almacén",
                                "Número de Fuego",
                                "Costo",
                                "Error",
                            ]);

                            response.data.data.forEach(item => {
                                dataExport.push([
                                    item[0],
                                    item[1],
                                    item[2],
                                    item[3],
                                    item[4],
                                ]);
                            });
                        } else {
                            dataExport.push([
                                "RUC",
                                "Marca Diseño Medida",
                                "Fecha de ingreso a almacén",
                                "Número de Fuego",
                                "Costo",
                                "Error",
                            ]);
                            response.data.data.forEach(item => {
                                dataExport.push([
                                    item[0],
                                    item[1],
                                    item[2],
                                    item[3],
                                    item[4],
                                    item[5],
                                ]);
                            });
                        }
                        const ws = XLSX.utils.aoa_to_sheet(dataExport);
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                        XLSX.writeFile(wb, 'neumáticos_no_cargados.xlsx');
                        this.textError = 'Algunos neumáticos no puedieron cargarse, se descargará un excel indicando cual fue el problema';
                    } else {
                        this.textError = 'Los neumáticos se han cargado exitosamente';
                    }
                    this.snackbarColorBtn = 'blue';
                    this.snackbar = true;
                    this.getDataFromApi()

                }).catch(error => {
                    console.error('error', error);
                    this.overlay = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
            exportExcel() {
                tireService.exportExcel(this.options.options).then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Neumáticos.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.loading = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            }
        }
    };
</script>

<style scoped>
    .button-add {
        width: 238px !important;
        height: 50px !important;
    }

</style>
