<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Crear Informe de Control e Inspección</h1>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid>
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <v-card>
                        <fieldset class="py-2 px-2">
                            <legend>Datos de la Unidad:</legend>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                            v-model="inspection.vehicle.license_plate"
                                            label="Unidad"
                                            readonly
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="currentVehicle.brand_name"
                                            label="Marca"
                                            readonly
                                            outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                            v-model="currentVehicle.model_name"
                                            label="Modelo"
                                            readonly
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                            v-model="currentVehicle.type_name"
                                            label="Tipo"
                                            readonly
                                            outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </fieldset>
                    </v-card>
                    <v-card>
                        <fieldset class="py-2 px-2">
                            <legend>Datos para Comparación</legend>
                            <v-text-field
                                    v-model="inspection.pressure"
                                    label="Presión"
                                    type="number"
                                    required
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    v-model="inspection.rtd"
                                    label="RTD de Retiro"
                                    type="number"
                                    required
                                    outlined
                            ></v-text-field>
                        </fieldset>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card>
                        <fieldset class="py-2 px-2">
                            <legend>Datos de la Inspección</legend>
                            <v-menu v-model="fromDateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            label="Fecha de Inspección"
                                            prepend-icon="event"
                                            readonly
                                            :value="date_value"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        locale="es-ES"
                                        v-model="inspection.date"
                                        no-title
                                        @input="fromDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                            <v-row>
                                <v-col cols="12" md="8">
                                    <v-text-field
                                            v-model="inspection.km_travel"
                                            label="Odómetro"
                                            type="number"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-checkbox
                                            v-model="inspection.reset"
                                            label="Reset"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                        </fieldset>
                    </v-card>
                    <v-card>
                        <fieldset class="py-2 px-2">
                            <legend>Otros Datos:</legend>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                            v-model="inspection.fuel_quantity"
                                            label="Cant. Conbustible"
                                            type="number"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select
                                            v-model="inspection.driver_id"
                                            :items="drivers"
                                            outlined
                                            item-text="name"
                                            item-value="id"
                                            label="Chofer"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-select
                                    v-model="inspection.route_id"
                                    :items="routes"
                                    outlined
                                    item-text="name"
                                    item-value="id"
                                    label="Ruta"
                            ></v-select>
                        </fieldset>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-card>
                        <div class="table-responsive">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-center border--right" colspan="19">Control de Neumáticos</th>
                                        <th class="text-center" colspan="10">Desgastes Irregulares</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left">Posición</th>
                                        <th class="text-left">N° de Fuego</th>
                                        <th class="text-left">Marca</th>
                                        <th class="text-left">Medida</th>
                                        <th class="text-left">Diseño</th>
                                        <th class="text-left">N° R</th>
                                        <th class="text-left">T.V.</th>
                                        <th class="text-left">Ext.</th>
                                        <th class="text-left min--width-1">RTD1</th>
                                        <th class="text-left min--width-1">RTD2</th>
                                        <th class="text-left min--width-1">RTD3</th>
                                        <th class="text-left min--width-1">RTD4</th>
                                        <th class="text-left min--width-1">RTD5</th>
                                        <th class="text-left min--width-1">RTD min.</th>
                                        <th class="text-left min--width-1">Presión</th>
                                        <th class="text-left min--width-1">Km. Montaje</th>
                                        <th class="text-left min--width-2">Observación</th>
                                        <th class="text-left min--width-2">Acciones</th>
                                        <th class="text-left border--right">Cumplió</th>
                                        <th class="text-left min--width-3">Tipo de Desgaste 1</th>
                                        <th class="text-left min--width-3">Nivel de Desgaste 1</th>
                                        <th class="text-left min--width-3">Tipo de Desgaste 2</th>
                                        <th class="text-left min--width-3">Nivel de Desgaste 2</th>
                                        <th class="text-left min--width-3">Tipo de Desgaste 3</th>
                                        <th class="text-left min--width-3">Nivel de Desgaste 3</th>
                                        <th class="text-left min--width-1">N° Piedras</th>
                                        <th class="text-left min--width-1">N° Cortes</th>
                                        <th class="text-left min--width-1">N° Perforaciones</th>
                                        <th class="text-left min--width-2">Foto</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in currentVehicle.tires" :key="item.id">
                                        <td>{{item.position}}</td>
                                        <td>{{item.fire_number}}</td>
                                        <td>{{item.brand ? item.brand.name : ''}}</td>
                                        <td>{{item.measure ? item.measure.name : ''}}</td>
                                        <td>{{item.design ? item.design.name : ''}}</td>
                                        <td>{{item.retread}}</td>
                                        <td>
                                            <v-checkbox
                                                    v-model="item.input_tv"
                                            ></v-checkbox>
                                        </td>
                                        <td>
                                            <v-checkbox
                                                    v-model="item.input_ext"
                                            ></v-checkbox>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_rtd1"
                                                    type="number"
                                                    v-on:input="calMinRtd($event, item)"
                                                    required
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_rtd2"
                                                    type="number"
                                                    v-on:input="calMinRtd($event, item)"
                                                    required
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_rtd3"
                                                    type="number"
                                                    v-on:input="calMinRtd($event, item)"
                                                    required
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_rtd4"
                                                    type="number"
                                                    v-on:input="calMinRtd($event, item)"
                                                    required
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_rtd5"
                                                    type="number"
                                                    v-on:input="calMinRtd($event, item)"
                                                    required
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_rtd_min"
                                                    type="number"
                                                    readonly
                                                    required
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_pressure"
                                                    type="number"
                                                    required
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="currentVehicle.km_travel"
                                                    type="number"
                                                    readonly
                                                    required
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_observation"
                                                    required
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_actions"
                                                    required
                                            ></v-text-field>
                                        </td>
                                        <td class="border--right">
                                            <v-checkbox
                                                    v-model="item.input_fulfilled"
                                            ></v-checkbox>
                                        </td>
                                        <td>
                                            <v-select
                                                    v-model="item.input_wear_id_1"
                                                    :items="wears"
                                                    outlined
                                                    item-text="name"
                                                    item-value="id"
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-select
                                                    v-model="item.input_wear_level_id_1"
                                                    :items="wearLevels"
                                                    outlined
                                                    item-text="name"
                                                    item-value="id"
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-select
                                                    v-model="item.input_wear_id_2"
                                                    :items="wears"
                                                    outlined
                                                    item-text="name"
                                                    item-value="id"
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-select
                                                    v-model="item.input_wear_level_id_2"
                                                    :items="wearLevels"
                                                    outlined
                                                    item-text="name"
                                                    item-value="id"
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-select
                                                    v-model="item.input_wear_id_3"
                                                    :items="wears"
                                                    outlined
                                                    item-text="name"
                                                    item-value="id"
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-select
                                                    v-model="item.input_wear_level_id_3"
                                                    :items="wearLevels"
                                                    outlined
                                                    item-text="name"
                                                    item-value="id"
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_number_stones"
                                                    v-intNumber
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_number_cuts"
                                                    v-intNumber
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="item.input_number_perforations"
                                                    v-intNumber
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-file-input
                                                    accept="image/*"
                                                    label="Foto"
                                                    @change="handleImage($event, item)"
                                                    outlined
                                                    prepend-icon="mdi-camera"
                                            ></v-file-input>
                                            <v-img
                                                    max-width="10rem"
                                                    style="margin: 0 auto"
                                                    v-bind:src="item.photo_url"
                                            ></v-img>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" align="center"
                       justify="space-around">
                    <v-btn depressed color="primary" @click="sendInspection" :disabled="loading || disabled" :loading="loading">
                        Actualizar
                    </v-btn>
                    <v-btn depressed :disabled="loading" :to="'/process/inspections'">
                        Cancelar
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import unitService from "@/services/units/unitService";
    import driverService from "@/services/driverService";
    import inspectionService from "@/services/inspectionService";
    import unitRouteService from "@/services/units/unitRouteService";
    import tireWearService from "@/services/tires/tireWearService";
    import tireWearLevelService from "@/services/tires/tireWearlevelService";
    import resourceService from '@/services/resourceService'

    export default {
        name: "InspectionEdit",
        components: {
            Breadcrumbs,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Control e Inspección', disabled: false, to: {name: 'Inspection'}, exact: true},
                {text: 'Editar Informe', disabled: true, to: {name: 'InspectionAdd'}, exact: true},
            ],
            fromDateMenu: false,
            drivers: [],
            vehicles: [],
            routes: [],
            wears: [],
            wearLevels: [],
            isLoadingSearch: false,
            search: null,
            inspection: {
                date: null,
                km_travel: null,
                reset: false,
                vehicle: {},
                vehicle_id: false,
                fuel_quantity: 0,
                pressure: 120,
                rtd: 3,
                tires: []
            },
            currentVehicle: {},
            snackbar: false,
            textError: '',
            loading: false,
            disabled: false,

        }),
        computed: {
            date_value() {
                return this.formatDate(this.inspection.date);
            }
        },
        mounted() {
            inspectionService.show(this.$route.params.id).then(response => {
                this.inspection = response.data.data;
                this.currentVehicle = this.inspection.vehicle;
                this.currentVehicle.brand_name = this.inspection.vehicle.brand_of_unit.name;
                this.currentVehicle.model_name = this.inspection.vehicle.unit_model.name;
                this.currentVehicle.type_name = this.inspection.vehicle.unit_type_axis.name;

                this.currentVehicle.tires = [];
                this.inspection.details.forEach((item, key) => {
                    let tire = item.tire;
                    Vue.set(tire, 'id', item.id);
                    Vue.set(tire, 'input_tv', item.tv);
                    Vue.set(tire, 'input_ext', item.ext);
                    Vue.set(tire, 'input_rtd1', item.rtd_1);
                    Vue.set(tire, 'input_rtd2', item.rtd_2);
                    Vue.set(tire, 'input_rtd3', item.rtd_3);
                    Vue.set(tire, 'input_rtd4', item.rtd_4);
                    Vue.set(tire, 'input_rtd5', item.rtd_5);
                    Vue.set(tire, 'input_rtd_min', item.rtd_min);
                    Vue.set(tire, 'input_pressure', item.pressure);
                    Vue.set(tire, 'input_observation', item.observation);
                    Vue.set(tire, 'input_actions', item.actions);
                    Vue.set(tire, 'input_fulfilled', item.fulfilled);
                    Vue.set(tire, 'input_wear_id_1', item.wear_id_1);
                    Vue.set(tire, 'input_wear_id_2', item.wear_id_2);
                    Vue.set(tire, 'input_wear_id_3', item.wear_id_3);
                    Vue.set(tire, 'input_wear_level_id_1', item.wear_level_id_1);
                    Vue.set(tire, 'input_wear_level_id_2', item.wear_level_id_2);
                    Vue.set(tire, 'input_wear_level_id_3', item.wear_level_id_3);
                    Vue.set(tire, 'input_number_stones', item.number_stones);
                    Vue.set(tire, 'input_number_cuts', item.input_number_cuts);
                    Vue.set(tire, 'input_number_perforations', item.number_perforations);
                    Vue.set(tire, 'photo', item.photo);
                    Vue.set(tire, 'photo_url', item.photo_url);

                    Vue.set(this.currentVehicle.tires, key, tire);
                });

                this.vehicles.push(this.inspection.vehicle);
            });

            driverService.all().then(response => {
                this.drivers = response.data.data;
            });
            unitRouteService.all().then(response => {
                this.routes = response.data.data;
            });
            tireWearService.all().then(response => {
                this.wears = response.data.data;
            });
            tireWearLevelService.all().then(response => {
                this.wearLevels = response.data.data;
            });
        },
        watch: {
            search(val) {
                // Items have already been loaded
                // if (this.tireTemplates.length > 0) return;

                // Items have already been requested
                if (this.isLoadingSearch) return;

                this.isLoadingSearch = true;
                // Lazily load input items
                unitService.search(val, 1).then(response => {
                    this.vehicles = response.data.data;
                    this.isLoadingSearch = false;
                }).catch(error => {
                    this.isLoadingSearch = false;
                    this.loading = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
            tire: function (newVal, oldVal) {
                if (newVal) {
                    this.tireTemplates.push(this.tire.tire_template);
                }
            }
        },
        methods: {
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
            onSelectVehicle(e) {
                this.inspection.vehicle_id = e.id;
                this.currentVehicle = e;
                this.currentVehicle.brand_name = e.brand_of_unit.name;
                this.currentVehicle.model_name = e.unit_model.name;
                this.currentVehicle.type_name = e.unit_type_axis.name;
            },
            calMinRtd(e, item) {
                const arr = [];
                if (item.input_rtd1 != null && item.input_rtd1 !== '' && !isNaN(item.input_rtd1)) {
                    arr.push(Number(item.input_rtd1));
                }
                if (item.input_rtd2 != null && item.input_rtd2 !== '' && !isNaN(item.input_rtd2)) {
                    arr.push(Number(item.input_rtd2));
                }
                if (item.input_rtd3 != null && item.input_rtd3 !== '' && !isNaN(item.input_rtd3)) {
                    arr.push(Number(item.input_rtd3));
                }
                if (item.input_rtd4 != null && item.input_rtd4 !== '' && !isNaN(item.input_rtd4)) {
                    arr.push(Number(item.input_rtd4));
                }
                if (item.input_rtd5 != null && item.input_rtd5 !== '' && !isNaN(item.input_rtd5)) {
                    arr.push(Number(item.input_rtd5));
                }
                const result = Math.min.apply(Math, arr);
                if (result === Infinity) {
                    item.input_rtd_min = null;
                } else {
                    item.input_rtd_min = result;
                }
            },
            sendInspection() {
                if (!this.inspection.date) {
                    this.textError = 'Complete el campo de fecha';
                    this.snackbar = true;
                    return;
                }
                if (!this.inspection.vehicle_id) {
                    this.textError = 'Seleccione un vehículo';
                    this.snackbar = true;
                    return;
                }
                if (!this.inspection.km_travel) {
                    this.textError = 'Complete el Odómetro';
                    this.snackbar = true;
                    return;
                }
                if (!this.inspection.pressure) {
                    this.textError = 'Complete el campo de Presión';
                    this.snackbar = true;
                    return;
                }
                if (!this.inspection.pressure) {
                    this.textError = 'Complete el campo RTD de Retiro';
                    this.snackbar = true;
                    return;
                }

                if (this.inspection.km_travel < this.currentVehicle.km_travel) {
                    this.textError = 'El valor del Odómetro no puede ser menos al que ya se recorrió';
                    this.snackbar = true;
                    return;
                }
                let rtdError = false;
                this.currentVehicle.tires.forEach(item => {
                    if (
                        (!item.input_rtd1 || isNaN(item.input_rtd1)) &&
                        (!item.input_rtd2 || isNaN(item.input_rtd2)) &&
                        (!item.input_rtd3 || isNaN(item.input_rtd3)) &&
                        (!item.input_rtd4 || isNaN(item.input_rtd4)) &&
                        (!item.input_rtd5 || isNaN(item.input_rtd5))
                    ) {
                        rtdError = true;
                    }
                });

                if (rtdError) {
                    this.textError = 'Debe ingresar al menos un campo de RTD para cada neumático';
                    this.snackbar = true;
                    return;
                }

                this.inspection.tires = this.currentVehicle.tires;
                this.loading = true;
                inspectionService.update(this.inspection).then(response => {
                    this.loading = false;
                    this.$router.push({path: '/process/inspections'});
                }).catch(error => {
                    this.loading = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
            handleImage(e, item) {
                this.disabled = true;
                const formData = new FormData();
                formData.append('file', e);
                formData.append('path', 'inspections');
                resourceService.uploadFile(formData).then(response => {
                    item.photo = response.data.data.name;
                    item.photo_url = response.data.data.url;
                    this.disabled = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    console.error('error', error);
                    this.disabled = false;
                });
            }
        }
    }
</script>

<style scoped>
    @media (max-width: 575.98px) {
        .table-responsive-sm {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }

        .table-responsive-sm > .table-bordered {
            border: 0;
        }
    }

    @media (max-width: 767.98px) {
        .table-responsive-md {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }

        .table-responsive-md > .table-bordered {
            border: 0;
        }
    }

    @media (max-width: 991.98px) {
        .table-responsive-lg {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }

        .table-responsive-lg > .table-bordered {
            border: 0;
        }
    }

    @media (max-width: 1199.98px) {
        .table-responsive-xl {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }

        .table-responsive-xl > .table-bordered {
            border: 0;
        }
    }

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive > .table-bordered {
        border: 0;
    }

    .border--right {
        border-right: thin solid rgba(0, 0, 0, 0.12);
        /*border-right: ;*/
    }

    .min--width-1 {
        min-width: 6rem;
    }

    .min--width-2 {
        min-width: 15rem;
    }

    .min--width-3 {
        min-width: 10rem;
    }
</style>
