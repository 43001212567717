<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Editar Falla de Neumatico</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <FailForm :fail="fail"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import FailForm from '@/components/tires/fail/FailForm'
    import tireFailService from '@/services/tires/tireFailService';

    export default {
        name: 'FailEdit',
        components: {
            Breadcrumbs,
            FailForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Fallas del Neumático', disabled: false, to: {name: 'TiresFail'}, exact: true},
                {text: 'Editar Falla de Neumático', disabled: true, to: {name: 'TiresFailEdit'}, exact: true},
            ],
            fail: {
                id: null,
                name: '',
                status: null,
                status_nice: 'Active',
                part_id: null,
                comment: '',
                photo: '',
                photo_url: '',
            }
        }),
        created() {
            this.getTireFailById(this.$route.params.id);
        },
        methods: {
            getTireFailById(id) {
                tireFailService.show(id).then(response => {
                    this.fail = response.data.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
