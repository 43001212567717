<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Ingresar Pesaje</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <PesajeForm :pesaje="pesaje"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import PesajeForm from '@/components/process/pesaje/PesajeForm'

    export default {
        name: 'PesajeAdd',
        components: {
            Breadcrumbs,
            PesajeForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Proceso', disabled: false, to: {name: 'Pesaje'}, exact: true},
                {text: 'Crear Control', disabled: true, to: {name: 'ProcessPesajeAdd'}, exact: true},
            ],
            pesaje: {
                customer_id: null,
                id: null,
                vehicle_id: null,
                date: null,
                observation: null,
            }
        })
    }
</script>

<style scoped>

</style>
