<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1>Editar Tipo Unidad</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UnitTypeForm :unittype="unittype"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitTypeForm from '@/components/units/type/UnitTypeForm';
    import unitTypeService from '@/services/units/unitTypeService';

    export default {
        name: 'UnitTypeEdit',
        components: {
            Breadcrumbs,
            UnitTypeForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Tipo de Unidades', disabled: false, to: {name: 'UnitType'}, exact: true},
                {text: 'Editar Tipo Unidad', disabled: true, to: {name: 'UnitTypeEdit'}, exact: true}
            ],
            unittype: {
                id: null,
                name: '',
                status: null,
                status_nice: '',
                photo: '',
                photo_url: ''
            },
        }),
        created() {
            this.getUnitTypeById(this.$route.params.id);
        },
        methods: {
            getUnitTypeById(id) {
                unitTypeService.show(id).then(response => {
                    this.unittype = response.data.data;
                }).catch(error => {
                   console.error(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
