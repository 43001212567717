<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" @submit.prevent="submit">
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="mobileApp.name"
                                :rules="rules.name"
                                label="Nombre"
                                required
                                outlined
                        ></v-text-field>
                        <v-text-field
                                v-model="mobileApp.version"
                                :rules="rules.version"
                                label="Versión"
                                required
                                outlined
                        ></v-text-field>

                        <v-file-input
                                accept=".apk"
                                label="APK"
                                @change="handleImage($event)"
                                outlined
                                prepend-icon="mdi-android-debug-bridge"
                        ></v-file-input>

                        <template v-if="mobileApp.file_url">
                            <div class="text-center">
                                <v-btn :href="mobileApp.file_url" elevation="2">Descargar {{mobileApp.name}}</v-btn>
                            </div>
                        </template>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn :disabled="!valid || loading" type="submit" v-if="!mobileApp.id" :loading="loading">
                                Guardar
                            </v-btn>
                        </div>
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn :disabled="!valid || loading" type="submit" v-if="mobileApp.id" :loading="loading">
                                Actualizar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import mobileAppService from "@/services/administrations/mobileAppService";
    import resourceService from '@/services/resourceService'

    export default {
        name: "MobileAppForm",
        props: ["mobileApp"],
        components: {},
        data: () => ({
            snackbar: false,
            textError: '',
            loading: false,
            valid: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
                version: [
                    v => !!v || 'Correo es requerido',
                ],
            },
            overlay: false,
        }),
        mounted() {
        },
        methods: {
            submit() {
                if (!this.mobileApp.file_url) {
                    this.textError = 'Debe adjuntar un APK';
                    this.snackbar = true;
                    return;
                }

                this.loading = true;
                if (this.mobileApp.id) {
                    mobileAppService.update(this.mobileApp).then(response => {
                        this.$router.push({path: '/administration/mobile-apps'})
                    }).catch(error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                } else {
                    mobileAppService.store(this.mobileApp).then(response => {
                        this.$router.push({path: '/administration/mobile-apps'})
                    }).catch(error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                }
            },
            handleImage(e) {
                if (e) {
                    this.overlay = true;
                    const formData = new FormData();
                    formData.append('file', e);
                    formData.append('path', 'apks');
                    formData.append('file_name', e.name);
                    resourceService.uploadFile(formData).then(response => {
                        this.overlay = false;
                        this.mobileApp.file = response.data.data.name;
                        this.mobileApp.file_url = response.data.data.url;
                    }).catch(error => {
                        this.overlay = false;
                        console.error('error', error);
                        this.textError = error.response.data.message;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
