<template>
  <div>
    <h1>Ventana</h1>
  </div>
</template>

<script>
export default {
  name: "Window",
};
</script>

<style scoped></style>
