<template>
    <v-row class="custom-font-size">
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>

        <v-col cols="12" sm="12" md="4" d="8">
            <div class="pl-5">
                <h2 class="title-content">Unidad</h2>
                <v-simple-table class="mb-2">
                    <template v-slot:default>
                        <tbody>
                        <tr>
                            <th class="text-left">Unidad</th>
                            <td>{{ unit.license_plate }}
                                <template v-if="unit.vehicle_number">({{ unit.vehicle_number }})</template>
                            </td>
                        </tr>
                        <tr>
                            <th class="text-left">Marca</th>
                            <td>{{ unit.brand_of_unit.name }}</td>
                        </tr>
                        <tr>
                            <th class="text-left">Modelo</th>
                            <td>{{ unit.unit_model.name }}</td>
                        </tr>
                        <tr>
                            <th class="text-left">Tipo de eje</th>
                            <td>{{ unit.unit_type_axis.name }}</td>
                        </tr>
                        <tr>
                            <th class="text-left">Km Recorridos</th>
                            <td>{{ unit.km_travel }}</td>
                        </tr>
                        <tr>
                            <th class="text-left">Horas Recorridas</th>
                            <td>{{ unit.time_travel }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <div class="mb-5">
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12" md="8">
                                    <v-text-field
                                        class="custom-font-size-input"
                                        v-model="tripCounterKm"
                                        :disabled="!customerSelected"
                                        type="number"
                                        label="Odómetro Km"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-checkbox
                                        class="custom-font-size-input"
                                        v-model="reset"
                                        :disabled="!customerSelected"
                                        label="Reset"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-text-field
                                class="custom-font-size-input"
                                v-model="tripCounterTime"
                                :disabled="!customerSelected"
                                type="number"
                                label="Horas h."
                                required
                            ></v-text-field>
                            <v-menu v-model="fromDateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        class="custom-font-size-input"
                                        :disabled="!customerSelected"
                                        label="Fecha de Asignación"
                                        prepend-icon="event"
                                        readonly
                                        :value="date_value"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="es-ES"
                                    v-model="date"
                                    no-title
                                    @input="fromDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </div>

                <h2>Neumáticos Asignados</h2>
                <v-simple-table class="mb-10 ">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">Pos</th>
                            <th class="text-left">N° de Fuego</th>
                            <th class="text-left">Marca</th>
                            <th class="text-left">Diseño</th>
                            <th class="text-left">Última Fecha Insp.</th>
                            <th class="text-left">Último RTD</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="item in tiresPositionFinal">
                            <tr v-if="item.id">
                                <td>{{ item.position }}</td>
                                <td>{{ item.fire_number }}</td>
                                <td>{{ item.brand ? item.brand.name : '' }}</td>
                                <td>{{ item.design ? item.design.name : '' }}</td>
                                <td>{{ item.last_inspection_date | dateFilter('DD/MM/YYYY') }}</td>
                                <td>{{ item.last_inspection_details_rtd }}</td>
                            </tr>
                        </template>
                        </tbody>
                    </template>
                </v-simple-table>
                <div class="text-center">
                    <v-btn depressed color="primary"
                           :disabled="loading || !customerSelected" :loading="loading" v-on:click="storeTires">
                        Guardar
                    </v-btn>
                </div>
            </div>
        </v-col>
        <v-col cols="12" sm="12" md="3" d="8">
            <div v-if="unit.unit_type_axis"
                 class="div-eje"
                 v-bind:style="{ backgroundImage: 'url(' + unit.unit_type_axis.photo_url + ')' }">
                <draggable class="tire1empty" :list="tiresPosition1" group="tiresPositions"
                           :options="{disabled : dragDisabled || !customerSelected}"
                           @change="logPosition($event, 'tiresPosition1')">
                    <div v-if="tiresPosition1[0]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <img src="../../../assets/images/llanta-asignacion.png" v-bind="attrs" v-on="on" alt="">
                            </template>
                            <span>{{ tiresPosition1[0].fire_number }}</span>
                        </v-tooltip>
                    </div>
                </draggable>
                <draggable class="tire2empty" :list="tiresPosition2" group="tiresPositions"
                           :options="{disabled : dragDisabled || !customerSelected}"
                           @change="logPosition($event, 'tiresPosition2')">
                    <div v-if="tiresPosition2[0]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <img src="../../../assets/images/llanta-asignacion.png" v-bind="attrs" v-on="on" alt="">
                            </template>
                            <span>{{ tiresPosition2[0].fire_number }}</span>
                        </v-tooltip>
                    </div>
                </draggable>
                <draggable class="tire3empty" :list="tiresPosition3" group="tiresPositions"
                           :options="{disabled : dragDisabled || !customerSelected}"
                           @change="logPosition($event, 'tiresPosition3')">
                    <div v-if="tiresPosition3[0]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <img src="../../../assets/images/llanta-asignacion.png" v-bind="attrs" v-on="on" alt="">
                            </template>
                            <span>{{ tiresPosition3[0].fire_number }}</span>
                        </v-tooltip>
                    </div>
                </draggable>
                <draggable class="tire4empty" :list="tiresPosition4" group="tiresPositions"
                           :options="{disabled : dragDisabled || !customerSelected}"
                           @change="logPosition($event, 'tiresPosition4')">
                    <div v-if="tiresPosition4[0]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <img src="../../../assets/images/llanta-asignacion.png" v-bind="attrs" v-on="on" alt="">
                            </template>
                            <span>{{ tiresPosition4[0].fire_number }}</span>
                        </v-tooltip>
                    </div>
                </draggable>
                <draggable class="tire5empty" :list="tiresPosition5" group="tiresPositions"
                           :options="{disabled : dragDisabled || !customerSelected}"
                           @change="logPosition($event, 'tiresPosition5')">
                    <div v-if="tiresPosition5[0]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <img src="../../../assets/images/llanta-asignacion.png" v-bind="attrs" v-on="on" alt="">
                            </template>
                            <span>{{ tiresPosition5[0].fire_number }}</span>
                        </v-tooltip>
                    </div>
                </draggable>
                <draggable class="tire6empty" :list="tiresPosition6" group="tiresPositions"
                           :options="{disabled : dragDisabled || !customerSelected}"
                           @change="logPosition($event, 'tiresPosition6')">
                    <div v-if="tiresPosition6[0]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <img src="../../../assets/images/llanta-asignacion.png" v-bind="attrs" v-on="on" alt="">
                            </template>
                            <span>{{ tiresPosition6[0].fire_number }}</span>
                        </v-tooltip>
                    </div>
                </draggable>
                <draggable class="tire7empty" :list="tiresPosition7" group="tiresPositions"
                           :options="{disabled : dragDisabled || !customerSelected}"
                           @change="logPosition($event, 'tiresPosition7')">
                    <div v-if="tiresPosition7[0]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <img src="../../../assets/images/llanta-asignacion.png" v-bind="attrs" v-on="on" alt="">
                            </template>
                            <span>{{ tiresPosition7[0].fire_number }}</span>
                        </v-tooltip>
                    </div>
                </draggable>
                <draggable class="tire8empty" :list="tiresPosition8" group="tiresPositions"
                           :options="{disabled : dragDisabled || !customerSelected}"
                           @change="logPosition($event, 'tiresPosition8')">
                    <div v-if="tiresPosition8[0]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <img src="../../../assets/images/llanta-asignacion.png" v-bind="attrs" v-on="on" alt="">
                            </template>
                            <span>{{ tiresPosition8[0].fire_number }}</span>
                        </v-tooltip>
                    </div>
                </draggable>
                <draggable class="tire9empty" :list="tiresPosition9" group="tiresPositions"
                           :options="{disabled : dragDisabled || !customerSelected}"
                           @change="logPosition($event, 'tiresPosition9')">
                    <div v-if="tiresPosition9[0]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <img src="../../../assets/images/llanta-asignacion.png" v-bind="attrs" v-on="on" alt="">
                            </template>
                            <span>{{ tiresPosition9[0].fire_number }}</span>
                        </v-tooltip>
                    </div>
                </draggable>
                <draggable class="tire10empty" :list="tiresPosition10" group="tiresPositions"
                           :options="{disabled : dragDisabled || !customerSelected}"
                           @change="logPosition($event, 'tiresPosition10')">
                    <div v-if="tiresPosition10[0]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <img src="../../../assets/images/llanta-asignacion.png" v-bind="attrs" v-on="on" alt="">
                            </template>
                            <span>{{ tiresPosition10[0].fire_number }}</span>
                        </v-tooltip>
                    </div>
                </draggable>
            </div>
        </v-col>
        <v-col cols="12" sm="12" md="5" d="8">
            <div>
                <div class="mb-10 ">
                    <h2>Almacén</h2>
                    <v-row class="mt-1">
                        <v-col cols="12" sm="2"><span><b>N° Fuego</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Marca</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Medida</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Diseño</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Última Fecha de Insp.</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Último RTD</b></span></v-col>
                    </v-row>
                    <v-row class="table-div">
                        <v-col>
                            <draggable :list="tiresStatus0" group="tiresPositions" @change="logPositionStore"
                                       :options="{disabled : dragDisabled || !customerSelected}">
                                <v-row v-for="item in tiresStatus0" :key="item.id">
                                    <v-col cols="12" sm="2">{{ item.fire_number }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.brand ? item.brand.name : '' }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.measure ? item.measure.name : '' }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.design ? item.design.name : '' }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.last_inspection_date | dateFilter('DD/MM/YYYY') }}
                                    </v-col>
                                    <v-col cols="12" sm="2">{{ item.last_inspection_details_rtd }}</v-col>
                                </v-row>
                            </draggable>
                        </v-col>
                    </v-row>
                </div>
                <div>
                    <h2>En Tránsito</h2>
                    <v-row class="mt-1">
                        <v-col cols="12" sm="2"><span><b>N° Fuego</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Marca</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Medida</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Diseño</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Última Fecha de Insp.</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Último RTD</b></span></v-col>
                    </v-row>
                    <v-row class="table-div">
                        <v-col>
                            <draggable :list="tiresStatus3" group="tiresPositions" @change="logPositionTransit"
                                       :options="{disabled : dragDisabled || !customerSelected}"
                                       @start="dragRetirementDisabled=true"
                                       @end="dragRetirementDisabled=false">
                                <v-row v-for="item in tiresStatus3" :key="item.id">
                                    <v-col cols="12" sm="2">{{ item.fire_number }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.brand ? item.brand.name : '' }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.measure ? item.measure.name : '' }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.design ? item.design.name : '' }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.last_inspection_date | dateFilter('DD/MM/YYYY') }}
                                    </v-col>
                                    <v-col cols="12" sm="2">{{ item.last_inspection_details_rtd }}</v-col>
                                </v-row>
                            </draggable>
                        </v-col>
                    </v-row>
                </div>
                <div>
                    <h2>Retiro</h2>
                    <v-row class="mt-1">
                        <v-col cols="12" sm="2"><span><b>N° Fuego</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Marca</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Medida</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Diseño</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Última Fecha de Insp.</b></span></v-col>
                        <v-col cols="12" sm="2"><span><b>Último RTD</b></span></v-col>
                    </v-row>
                    <v-row class="table-div">
                        <v-col>
                            <draggable :list="tiresStatusRetirement" group="tiresPositions"
                                       @change="logPositionRetirement"
                                       :options="{disabled : dragRetirementDisabled || !customerSelected}"
                                       @start="dragDisabled=true"
                                       @end="dragDisabled=false">
                                <v-row v-for="item in tiresStatusRetirement" :key="item.id">
                                    <v-col cols="12" sm="2">{{ item.fire_number }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.brand ? item.brand.name : '' }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.measure ? item.measure.name : '' }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.design ? item.design.name : '' }}</v-col>
                                    <v-col cols="12" sm="2">{{ item.last_inspection_date | dateFilter('DD/MM/YYYY') }}
                                    </v-col>
                                    <v-col cols="12" sm="2">{{ item.last_inspection_details_rtd }}</v-col>
                                </v-row>
                            </draggable>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-col>

        <v-dialog v-model="dialog" persistent max-width="390">
            <v-card>
                <v-card-title class="headline">
                    Retiro de Neumáticos
                </v-card-title>
                <v-card-text>
                    <v-col cols="12">
                        <v-select
                            v-model="tire_removal.status"
                            :disabled="!customerSelected"
                            :items="allstatus"
                            outlined
                            item-text="name"
                            item-value="id"
                            label="Estado"
                        ></v-select>

                        <v-text-field
                            v-model="tire_removal.rtd"
                            :disabled="!customerSelected"
                            label="RTD"
                            type="number"
                            required
                            outlined
                        ></v-text-field>
                        <v-select
                            v-model="tire_removal.part_id"
                            :disabled="!customerSelected"
                            :items="parts"
                            @change="getFails"
                            outlined
                            item-text="name"
                            item-value="id"
                            label="Parte"
                        ></v-select>
                        <v-select
                            v-model="tire_removal.fail_id"
                            :disabled="!customerSelected"
                            :items="fails"
                            outlined
                            item-text="name"
                            item-value="id"
                            label="Falla"
                        ></v-select>
                        <v-select
                            v-model="tire_removal.causal_id"
                            :disabled="!customerSelected"
                            :items="causals"
                            outlined
                            item-text="name"
                            item-value="id"
                            label="Causal"
                        ></v-select>
                        <v-file-input
                            :disabled="!customerSelected"
                            accept="image/*"
                            label="Foto"
                            @change="handleImage($event)"
                            outlined
                            prepend-icon="mdi-camera"
                        ></v-file-input>
                        <v-text-field
                            v-if="tire_removal.status === 4"
                            v-model="tire_removal.dot"
                            :disabled="!customerSelected"
                            label="DOT"
                            v-intNumber
                            required
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" color="green darken-1" text @click="cancelTireRemoval">Cancelar</v-btn>
                    <v-btn type="submit" color="green darken-1" text @click="addTireRemoval">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogTransit" persistent max-width="390">
            <v-card>
                <v-card-title class="headline">
                    Movimiento de Neumáticos a Tránsito
                </v-card-title>
                <v-card-text>
                    <v-col cols="12" v-if="tireForTransit">
                        <v-text-field
                            v-model="tireForTransit.rtd"
                            type="number"
                            label="RTD"
                            required
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" color="green darken-1" text @click="cancelTireToTransit">Cancelar</v-btn>
                    <v-btn type="submit" color="green darken-1" text @click="addTireToTransit">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogRotating" persistent max-width="390">
            <v-card>
                <v-card-title class="headline">
                    Movimiento de Neumáticos
                </v-card-title>
                <v-card-text>
                    <v-col cols="12" v-if="tireForRotating">
                        <v-text-field
                            v-model="tireForRotating.rtd"
                            type="number"
                            label="RTD"
                            required
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" color="green darken-1" text @click="addRtdRotating">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import Vue from "vue";
import unitService from '@/services/units/unitService';
import draggable from 'vuedraggable';
import tireService from '@/services/tires/tireService';
import tirePartService from '@/services/tires/tirePartService';
import tireFailService from '@/services/tires/tireFailService';
import tireCausalService from '@/services/tires/tireCausalService';
import resourceService from '@/services/resourceService'
import customerService from "@/services/administrations/customerService";

export default {
    name: "UnitTires22D2",
    props: ['unit'],
    components: {
        draggable,
    },
    data: () => ({
        loading: false,
        tiresPosition1: [],
        tiresPosition2: [],
        tiresPosition3: [],
        tiresPosition4: [],
        tiresPosition5: [],
        tiresPosition6: [],
        tiresPosition7: [],
        tiresPosition8: [],
        tiresPosition9: [],
        tiresPosition10: [],
        tiresPositionFinal: [],
        tiresStatus0: [],
        tiresStatus3: [],
        tireInPosition: null,
        tiresStatusRetirement: [],
        dialog: false,
        dialogTransit: false,
        tireForTransit: null,
        dialogRotating: false,
        tireForRotating: null,
        snackbar: false,
        textError: '',
        tire_removal: {
            status: null,
            dot: null,
            rtd: null,
            part_id: null,
            fail_id: null,
            causal_id: null,
            photo: null,
            photo_url: null,
        },
        allstatus: [
            {id: 5, name: 'Reparación'},
            {id: 2, name: 'Reencauche'},
            {id: 4, name: 'Desecho'},
            {id: 6, name: 'Venta'},
        ],
        parts: [],
        fails: [],
        causals: [],
        currentTireRemoval: null,
        dragRetirementDisabled: false,
        dragDisabled: false,
        reset: false,
        tripCounterKm: null,
        tripCounterTime: null,

        fromDateMenu: false,
        fromDateVal: null,
        date: null,

        user: null,
        isAdmin: false,
        customers: [],
        customerSelected: true
    }),
    mounted() {
        this.user = this.$store.getters.userSession;
        this.isAdmin = true;
        this.user.roles.forEach(role => {
            if (role.name === 'customer') {
                this.isAdmin = false;
            }
        });
        if (this.isAdmin) {
            this.customerSelected = false;
            this.loadData().then(() => {
                this.customerSelected = true;
            });
        } else {
            this.loadData();
        }
    },
    computed: {
        date_value() {
            return this.formatDate(this.date);
        }
    },
    methods: {
        loadData() {
            return new Promise((resolve, reject) => {
                this.tiresPositionFinal = this.unit.tires;
                this.buildTiresPositionImage();
                tireService.all('0,3', this.unit.customer_id).then(response => {
                    this.tiresStatus0 = response.data.data.filter(item => item.status === 0);
                    this.tiresStatus3 = response.data.data.filter(item => item.status === 3);
                    resolve(true);
                });
                tirePartService.all().then(response => {
                    this.parts = response.data.data;
                });
                tireCausalService.all().then(response => {
                    this.causals = response.data.data;
                });
            });
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`
        },
        handleImage(e) {
            const formData = new FormData();
            formData.append('file', e);
            formData.append('path', 'retreats');
            resourceService.uploadFile(formData).then(response => {
                this.tire_removal.photo = response.data.data.name;
                this.tire_removal.photo_url = response.data.data.url;
            }).catch(error => {
                console.error('error', error);
            });
        },
        getFails() {
            tireFailService.all(this.tire_removal.part_id).then(response => {
                this.fails = response.data.data;
            });
        },
        buildTiresPositionFinal() {
            if (this.tiresPosition1[0]) {
                let pos = this.tiresPosition1[0];
                pos.position = 1;
                Vue.set(this.tiresPositionFinal, 0, pos);
            } else {
                Vue.set(this.tiresPositionFinal, 0, {});
            }

            if (this.tiresPosition2[0]) {
                let pos = this.tiresPosition2[0];
                pos.position = 2;
                Vue.set(this.tiresPositionFinal, 1, pos);
            } else {
                Vue.set(this.tiresPositionFinal, 1, {});
            }

            if (this.tiresPosition3[0]) {
                let pos = this.tiresPosition3[0];
                pos.position = 3;
                Vue.set(this.tiresPositionFinal, 2, pos);
            } else {
                Vue.set(this.tiresPositionFinal, 2, {});
            }

            if (this.tiresPosition4[0]) {
                let pos = this.tiresPosition4[0];
                pos.position = 4;
                Vue.set(this.tiresPositionFinal, 3, pos);
            } else {
                Vue.set(this.tiresPositionFinal, 3, {});
            }

            if (this.tiresPosition5[0]) {
                let pos = this.tiresPosition5[0];
                pos.position = 5;
                Vue.set(this.tiresPositionFinal, 4, pos);
            } else {
                Vue.set(this.tiresPositionFinal, 4, {});
            }

            if (this.tiresPosition6[0]) {
                let pos = this.tiresPosition6[0];
                pos.position = 6;
                Vue.set(this.tiresPositionFinal, 5, pos);
            } else {
                Vue.set(this.tiresPositionFinal, 5, {});
            }

            if (this.tiresPosition7[0]) {
                let pos = this.tiresPosition7[0];
                pos.position = 7;
                Vue.set(this.tiresPositionFinal, 6, pos);
            } else {
                Vue.set(this.tiresPositionFinal, 6, {});
            }

            if (this.tiresPosition8[0]) {
                let pos = this.tiresPosition8[0];
                pos.position = 8;
                Vue.set(this.tiresPositionFinal, 7, pos);
            } else {
                Vue.set(this.tiresPositionFinal, 7, {});
            }

            if (this.tiresPosition9[0]) {
                let pos = this.tiresPosition9[0];
                pos.position = 9;
                Vue.set(this.tiresPositionFinal, 8, pos);
            } else {
                Vue.set(this.tiresPositionFinal, 8, {});
            }

            if (this.tiresPosition10[0]) {
                let pos = this.tiresPosition10[0];
                pos.position = 10;
                Vue.set(this.tiresPositionFinal, 9, pos);
            } else {
                Vue.set(this.tiresPositionFinal, 9, {});
            }
        },
        buildTiresPositionImage() {
            this.tiresPositionFinal.forEach(itemPositionFinal => {
                if (itemPositionFinal.position === 1) {
                    Vue.set(this.tiresPosition1, 0, itemPositionFinal);
                } else if (itemPositionFinal.position === 2) {
                    Vue.set(this.tiresPosition2, 0, itemPositionFinal);
                } else if (itemPositionFinal.position === 3) {
                    Vue.set(this.tiresPosition3, 0, itemPositionFinal);
                } else if (itemPositionFinal.position === 4) {
                    Vue.set(this.tiresPosition4, 0, itemPositionFinal);
                } else if (itemPositionFinal.position === 5) {
                    Vue.set(this.tiresPosition5, 0, itemPositionFinal);
                } else if (itemPositionFinal.position === 6) {
                    Vue.set(this.tiresPosition6, 0, itemPositionFinal);
                } else if (itemPositionFinal.position === 7) {
                    Vue.set(this.tiresPosition7, 0, itemPositionFinal);
                } else if (itemPositionFinal.position === 8) {
                    Vue.set(this.tiresPosition8, 0, itemPositionFinal);
                } else if (itemPositionFinal.position === 9) {
                    Vue.set(this.tiresPosition9, 0, itemPositionFinal);
                } else if (itemPositionFinal.position === 10) {
                    Vue.set(this.tiresPosition10, 0, itemPositionFinal);
                }
            });
        },
        logPosition(evt, arrayName) {
            if (evt.added) {
                if (arrayName === 'tiresPosition1') {
                    if (this.tiresPosition1.length === 0 || this.tiresPosition1.length === 1) {
                        if (evt.added.element.status != 0 && evt.added.element.status != 3) {
                            this.tireForRotating = evt.added.element;
                            this.dialogRotating = true;
                        }
                        Vue.set(this.tiresPosition1, 0, evt.added.element);
                    } else {
                        if (evt.added.element.position > 0) {
                            this.restoreFinalPositions(evt.added.element, 1, evt.added.element.position);
                        } else {
                            this.tiresPosition1 = this.tiresPosition1.filter(tiresPositionItem => tiresPositionItem.id != evt.added.element.id);
                            // const currentItem = this.tiresPosition1[0];
                            if (evt.added.element.status === 0) {
                                this.tiresStatus0.push(evt.added.element);
                            } else if (evt.added.element.status === 3 || evt.added.element.status === 1) {
                                this.tiresStatus3.push(evt.added.element);
                            }
                            // Vue.set(this.tiresPosition1, 0, evt.added.element);
                        }
                    }
                } else if (arrayName === 'tiresPosition2') {
                    if (this.tiresPosition2.length === 0 || this.tiresPosition2.length === 1) {
                        if (evt.added.element.status != 0 && evt.added.element.status != 3) {
                            this.tireForRotating = evt.added.element;
                            this.dialogRotating = true;
                        }
                        Vue.set(this.tiresPosition2, 0, evt.added.element);
                    } else {
                        if (evt.added.element.position > 0) {
                            this.restoreFinalPositions(evt.added.element, 2, evt.added.element.position);
                        } else {
                            this.tiresPosition2 = this.tiresPosition2.filter(tiresPositionItem => tiresPositionItem.id != evt.added.element.id);
                            // const currentItem = this.tiresPosition2[0];
                            if (evt.added.element.status === 0) {
                                this.tiresStatus0.push(evt.added.element);
                            } else if (evt.added.element.status === 3 || evt.added.element.status === 1) {
                                this.tiresStatus3.push(evt.added.element);
                            }
                            // Vue.set(this.tiresPosition2, 0, evt.added.element);
                        }
                    }
                } else if (arrayName === 'tiresPosition3') {
                    if (this.tiresPosition3.length === 0 || this.tiresPosition3.length === 1) {
                        if (evt.added.element.status != 0 && evt.added.element.status != 3) {
                            this.tireForRotating = evt.added.element;
                            this.dialogRotating = true;
                        }
                        Vue.set(this.tiresPosition3, 0, evt.added.element);
                    } else {
                        if (evt.added.element.position > 0) {
                            this.restoreFinalPositions(evt.added.element, 3, evt.added.element.position);
                        } else {
                            this.tiresPosition3 = this.tiresPosition3.filter(tiresPositionItem => tiresPositionItem.id != evt.added.element.id);
                            // const currentItem = this.tiresPosition3[0];
                            if (evt.added.element.status === 0) {
                                this.tiresStatus0.push(evt.added.element);
                            } else if (evt.added.element.status === 3 || evt.added.element.status === 1) {
                                this.tiresStatus3.push(evt.added.element);
                            }
                            // Vue.set(this.tiresPosition3, 0, evt.added.element);
                        }
                    }
                } else if (arrayName === 'tiresPosition4') {
                    if (this.tiresPosition4.length === 0 || this.tiresPosition4.length === 1) {
                        if (evt.added.element.status != 0 && evt.added.element.status != 3) {
                            this.tireForRotating = evt.added.element;
                            this.dialogRotating = true;
                        }
                        Vue.set(this.tiresPosition4, 0, evt.added.element);
                    } else {
                        if (evt.added.element.position > 0) {
                            this.restoreFinalPositions(evt.added.element, 4, evt.added.element.position);
                        } else {
                            this.tiresPosition4 = this.tiresPosition4.filter(tiresPositionItem => tiresPositionItem.id != evt.added.element.id);
                            // const currentItem = this.tiresPosition4[0];
                            if (evt.added.element.status === 0) {
                                this.tiresStatus0.push(evt.added.element);
                            } else if (evt.added.element.status === 3 || evt.added.element.status === 1) {
                                this.tiresStatus3.push(evt.added.element);
                            }
                            // Vue.set(this.tiresPosition4, 0, evt.added.element);
                        }
                    }
                } else if (arrayName === 'tiresPosition5') {
                    if (this.tiresPosition5.length === 0 || this.tiresPosition5.length === 1) {
                        if (evt.added.element.status != 0 && evt.added.element.status != 3) {
                            this.tireForRotating = evt.added.element;
                            this.dialogRotating = true;
                        }
                        Vue.set(this.tiresPosition5, 0, evt.added.element);
                    } else {
                        if (evt.added.element.position > 0) {
                            this.restoreFinalPositions(evt.added.element, 5, evt.added.element.position);
                        } else {
                            this.tiresPosition5 = this.tiresPosition5.filter(tiresPositionItem => tiresPositionItem.id != evt.added.element.id);
                            // const currentItem = this.tiresPosition5[0];
                            if (evt.added.element.status === 0) {
                                this.tiresStatus0.push(evt.added.element);
                            } else if (evt.added.element.status === 3 || evt.added.element.status === 1) {
                                this.tiresStatus3.push(evt.added.element);
                            }
                            // Vue.set(this.tiresPosition5, 0, evt.added.element);
                        }
                    }
                } else if (arrayName === 'tiresPosition6') {
                    if (this.tiresPosition6.length === 0 || this.tiresPosition6.length === 1) {
                        if (evt.added.element.status != 0 && evt.added.element.status != 3) {
                            this.tireForRotating = evt.added.element;
                            this.dialogRotating = true;
                        }
                        Vue.set(this.tiresPosition6, 0, evt.added.element);
                    } else {
                        if (evt.added.element.position > 0) {
                            this.restoreFinalPositions(evt.added.element, 6, evt.added.element.position);
                        } else {
                            this.tiresPosition6 = this.tiresPosition6.filter(tiresPositionItem => tiresPositionItem.id != evt.added.element.id);
                            // const currentItem = this.tiresPosition6[0];
                            if (evt.added.element.status === 0) {
                                this.tiresStatus0.push(evt.added.element);
                            } else if (evt.added.element.status === 3 || evt.added.element.status === 1) {
                                this.tiresStatus3.push(evt.added.element);
                            }
                            // Vue.set(this.tiresPosition6, 0, evt.added.element);
                        }
                    }
                } else if (arrayName === 'tiresPosition7') {
                    if (this.tiresPosition7.length === 0 || this.tiresPosition7.length === 1) {
                        if (evt.added.element.status != 0 && evt.added.element.status != 3) {
                            this.tireForRotating = evt.added.element;
                            this.dialogRotating = true;
                        }
                        Vue.set(this.tiresPosition7, 0, evt.added.element);
                    } else {
                        if (evt.added.element.position > 0) {
                            this.restoreFinalPositions(evt.added.element, 7, evt.added.element.position);
                        } else {
                            this.tiresPosition7 = this.tiresPosition7.filter(tiresPositionItem => tiresPositionItem.id != evt.added.element.id);
                            // const currentItem = this.tiresPosition7[0];
                            if (evt.added.element.status === 0) {
                                this.tiresStatus0.push(evt.added.element);
                            } else if (evt.added.element.status === 3 || evt.added.element.status === 1) {
                                this.tiresStatus3.push(evt.added.element);
                            }
                            // Vue.set(this.tiresPosition7, 0, evt.added.element);
                        }
                    }
                } else if (arrayName === 'tiresPosition8') {
                    if (this.tiresPosition8.length === 0 || this.tiresPosition8.length === 1) {
                        if (evt.added.element.status != 0 && evt.added.element.status != 3) {
                            this.tireForRotating = evt.added.element;
                            this.dialogRotating = true;
                        }
                        Vue.set(this.tiresPosition8, 0, evt.added.element);
                    } else {
                        if (evt.added.element.position > 0) {
                            this.restoreFinalPositions(evt.added.element, 8, evt.added.element.position);
                        } else {
                            this.tiresPosition8 = this.tiresPosition8.filter(tiresPositionItem => tiresPositionItem.id != evt.added.element.id);
                            // const currentItem = this.tiresPosition8[0];
                            if (evt.added.element.status === 0) {
                                this.tiresStatus0.push(evt.added.element);
                            } else if (evt.added.element.status === 3 || evt.added.element.status === 1) {
                                this.tiresStatus3.push(evt.added.element);
                            }
                            // Vue.set(this.tiresPosition8, 0, evt.added.element);
                        }
                    }
                } else if (arrayName === 'tiresPosition9') {
                    if (this.tiresPosition9.length === 0 || this.tiresPosition9.length === 1) {
                        if (evt.added.element.status != 0 && evt.added.element.status != 3) {
                            this.tireForRotating = evt.added.element;
                            this.dialogRotating = true;
                        }
                        Vue.set(this.tiresPosition9, 0, evt.added.element);
                    } else {
                        if (evt.added.element.position > 0) {
                            this.restoreFinalPositions(evt.added.element, 9, evt.added.element.position);
                        } else {
                            this.tiresPosition9 = this.tiresPosition9.filter(tiresPositionItem => tiresPositionItem.id != evt.added.element.id);
                            // const currentItem = this.tiresPosition9[0];
                            if (evt.added.element.status === 0) {
                                this.tiresStatus0.push(evt.added.element);
                            } else if (evt.added.element.status === 3 || evt.added.element.status === 1) {
                                this.tiresStatus3.push(evt.added.element);
                            }
                            // Vue.set(this.tiresPosition9, 0, evt.added.element);
                        }

                    }
                } else if (arrayName === 'tiresPosition10') {
                    if (this.tiresPosition10.length === 0 || this.tiresPosition10.length === 1) {
                        if (evt.added.element.status != 0 && evt.added.element.status != 3) {
                            this.tireForRotating = evt.added.element;
                            this.dialogRotating = true;
                        }
                        Vue.set(this.tiresPosition10, 0, evt.added.element);
                    } else {
                        if (evt.added.element.position > 0) {
                            this.restoreFinalPositions(evt.added.element, 10, evt.added.element.position);
                        } else {
                            this.tiresPosition10 = this.tiresPosition10.filter(tiresPositionItem => tiresPositionItem.id != evt.added.element.id);
                            // const currentItem = this.tiresPosition10[0];
                            if (evt.added.element.status === 0) {
                                this.tiresStatus0.push(evt.added.element);
                            } else if (evt.added.element.status === 3 || evt.added.element.status === 1) {
                                this.tiresStatus3.push(evt.added.element);
                            }
                            // Vue.set(this.tiresPosition10, 0, evt.added.element);
                        }

                    }
                }
                this.buildTiresPositionFinal();
            }
        },
        addRtdRotating() {
            this.tireForRotating = null;
            this.dialogRotating = false;
        },
        restoreFinalPositions(element, newPosition, oldPosition) {
            if (newPosition === 1) {
                const index = this.tiresPosition1.findIndex(item => item.id === element.id);
                this.tiresPosition1.splice(index, 1);
            } else if (newPosition === 2) {
                const index = this.tiresPosition2.findIndex(item => item.id === element.id);
                this.tiresPosition2.splice(index, 1);
            } else if (newPosition === 3) {
                const index = this.tiresPosition3.findIndex(item => item.id === element.id);
                this.tiresPosition3.splice(index, 1);
            } else if (newPosition === 4) {
                const index = this.tiresPosition4.findIndex(item => item.id === element.id);
                this.tiresPosition4.splice(index, 1);
            } else if (newPosition === 5) {
                const index = this.tiresPosition5.findIndex(item => item.id === element.id);
                this.tiresPosition5.splice(index, 1);
            } else if (newPosition === 6) {
                const index = this.tiresPosition6.findIndex(item => item.id === element.id);
                this.tiresPosition6.splice(index, 1);
            } else if (newPosition === 7) {
                const index = this.tiresPosition7.findIndex(item => item.id === element.id);
                this.tiresPosition7.splice(index, 1);
            } else if (newPosition === 8) {
                const index = this.tiresPosition8.findIndex(item => item.id === element.id);
                this.tiresPosition8.splice(index, 1);
            } else if (newPosition === 9) {
                const index = this.tiresPosition9.findIndex(item => item.id === element.id);
                this.tiresPosition9.splice(index, 1);
            } else if (newPosition === 10) {
                const index = this.tiresPosition10.findIndex(item => item.id === element.id);
                this.tiresPosition10.splice(index, 1);
            }

            if (oldPosition === 1) {
                Vue.set(this.tiresPosition1, 0, element);
            } else if (oldPosition === 2) {
                Vue.set(this.tiresPosition2, 0, element);
            } else if (oldPosition === 3) {
                Vue.set(this.tiresPosition3, 0, element);
            } else if (oldPosition === 4) {
                Vue.set(this.tiresPosition4, 0, element);
            } else if (oldPosition === 5) {
                Vue.set(this.tiresPosition5, 0, element);
            } else if (oldPosition === 6) {
                Vue.set(this.tiresPosition6, 0, element);
            } else if (oldPosition === 7) {
                Vue.set(this.tiresPosition7, 0, element);
            } else if (oldPosition === 8) {
                Vue.set(this.tiresPosition8, 0, element);
            } else if (oldPosition === 9) {
                Vue.set(this.tiresPosition9, 0, element);
            } else if (oldPosition === 10) {
                Vue.set(this.tiresPosition10, 0, element);
            }
        },
        logPositionStore(evt) {
            if (evt.added) {
                if (evt.added.element.vehicle_id) {
                    if (evt.added.element.position === 1) {
                        this.logPosition(evt, 'tiresPosition1');
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatus0.splice(index, 1);
                    } else if (evt.added.element.position === 2) {
                        this.logPosition(evt, 'tiresPosition2');
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatus0.splice(index, 1);
                    } else if (evt.added.element.position === 3) {
                        this.logPosition(evt, 'tiresPosition3');
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatus0.splice(index, 1);
                    } else if (evt.added.element.position === 4) {
                        this.logPosition(evt, 'tiresPosition4');
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatus0.splice(index, 1);
                    } else if (evt.added.element.position === 5) {
                        this.logPosition(evt, 'tiresPosition5');
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatus0.splice(index, 1);
                    } else if (evt.added.element.position === 6) {
                        this.logPosition(evt, 'tiresPosition6');
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatus0.splice(index, 1);
                    } else if (evt.added.element.position === 7) {
                        this.logPosition(evt, 'tiresPosition7');
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatus0.splice(index, 1);
                    } else if (evt.added.element.position === 8) {
                        this.logPosition(evt, 'tiresPosition8');
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatus0.splice(index, 1);
                    } else if (evt.added.element.position === 9) {
                        this.logPosition(evt, 'tiresPosition9');
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatus0.splice(index, 1);
                    } else if (evt.added.element.position === 10) {
                        this.logPosition(evt, 'tiresPosition10');
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatus0.splice(index, 1);
                    } else if (evt.added.element.position === 0) {
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatus0.splice(index, 1);
                        this.tiresStatus3.push(evt.added.element);
                    }
                } else if (evt.added.element.status === 3) {
                    const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                    this.tiresStatus0.splice(index, 1);
                    this.tiresStatus3.push(evt.added.element);
                } else {
                    evt.added.element.position = 0;
                }
                this.buildTiresPositionFinal();

                // evt.added.element.vehicle_id = null;
            }
        },
        logPositionTransit(evt) {
            if (evt.added) {
                if (evt.added.element.status === 0) {
                    let index = this.tiresStatusRetirement.findIndex(item => item.id === evt.added.element.id);
                    this.tiresStatusRetirement.splice(index, 1);

                    index = this.tiresStatus3.findIndex(item => item.id === evt.added.element.id);
                    this.tiresStatus3.splice(index, 1);
                    this.tiresStatus0.push(evt.added.element);
                } else {
                    this.dialogTransit = true;
                    this.tireForTransit = evt.added.element;

                    // evt.added.element.position = 0;
                    // this.buildTiresPositionFinal();
                }
            }
        },
        addTireToTransit() {
            if (this.tireForTransit.rtd == null || this.tireForTransit.rtd == '') {
                this.textError = 'Complete el campo de RTD';
                this.snackbar = true;
                return;
            }
            this.tireForTransit.position = 0;
            this.buildTiresPositionFinal();
            this.dialogTransit = false;
            this.tireForTransit = null;
        },
        cancelTireToTransit() {
            let index = this.tiresStatusRetirement.findIndex(item => item.id === this.tireForTransit.id);
            this.tiresStatusRetirement.splice(index, 1);

            index = this.tiresStatus3.findIndex(item => item.id === this.tireForTransit.id);
            this.tiresStatus3.splice(index, 1);

            if (this.tireForTransit.position === 1) {
                Vue.set(this.tiresPosition1, 0, this.tireForTransit);
            } else if (this.tireForTransit.position === 2) {
                Vue.set(this.tiresPosition2, 0, this.tireForTransit);
            } else if (this.tireForTransit.position === 3) {
                Vue.set(this.tiresPosition3, 0, this.tireForTransit);
            } else if (this.tireForTransit.position === 4) {
                Vue.set(this.tiresPosition4, 0, this.tireForTransit);
            } else if (this.tireForTransit.position === 5) {
                Vue.set(this.tiresPosition5, 0, this.tireForTransit);
            } else if (this.tireForTransit.position === 6) {
                Vue.set(this.tiresPosition6, 0, this.tireForTransit);
            } else if (this.tireForTransit.position === 7) {
                Vue.set(this.tiresPosition7, 0, this.tireForTransit);
            } else if (this.tireForTransit.position === 8) {
                Vue.set(this.tiresPosition8, 0, this.tireForTransit);
            } else if (this.tireForTransit.position === 9) {
                Vue.set(this.tiresPosition9, 0, this.tireForTransit);
            } else if (this.tireForTransit.position === 10) {
                Vue.set(this.tiresPosition10, 0, this.tireForTransit);
            }

            this.dialogTransit = false;
            this.tireForTransit = null;
        },
        logPositionRetirement(evt) {
            if (evt.added) {
                if (evt.added.element.position > 0) {
                    this.dialog = true;
                    this.currentTireRemoval = evt.added.element;
                } else {
                    if (evt.added.element.status === 0) {
                        const index = this.tiresStatus0.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatusRetirement.splice(index, 1);
                        this.tiresStatus0.push(evt.added.element);
                    } else if (evt.added.element.status === 3) {
                        const index = this.tiresStatus3.findIndex(item => item.id === evt.added.element.id);
                        this.tiresStatusRetirement.splice(index, 1);
                        this.tiresStatus3.push(evt.added.element);
                    }
                }
            }
        },
        cancelTireRemoval() {
            if (this.currentTireRemoval.position === 1) {
                Vue.set(this.tiresPosition1, 0, this.currentTireRemoval);
            } else if (this.currentTireRemoval.position === 2) {
                Vue.set(this.tiresPosition2, 0, this.currentTireRemoval);
            } else if (this.currentTireRemoval.position === 3) {
                Vue.set(this.tiresPosition3, 0, this.currentTireRemoval);
            } else if (this.currentTireRemoval.position === 4) {
                Vue.set(this.tiresPosition4, 0, this.currentTireRemoval);
            } else if (this.currentTireRemoval.position === 5) {
                Vue.set(this.tiresPosition5, 0, this.currentTireRemoval);
            } else if (this.currentTireRemoval.position === 6) {
                Vue.set(this.tiresPosition6, 0, this.currentTireRemoval);
            } else if (this.currentTireRemoval.position === 7) {
                Vue.set(this.tiresPosition7, 0, this.currentTireRemoval);
            } else if (this.currentTireRemoval.position === 8) {
                Vue.set(this.tiresPosition8, 0, this.currentTireRemoval);
            } else if (this.currentTireRemoval.position === 9) {
                Vue.set(this.tiresPosition9, 0, this.currentTireRemoval);
            } else if (this.currentTireRemoval.position === 10) {
                Vue.set(this.tiresPosition10, 0, this.currentTireRemoval);
            }
            const index = this.tiresStatusRetirement.findIndex(item => item.id === this.currentTireRemoval.id);
            this.tiresStatusRetirement.splice(index, 1);
            this.dialog = false;
        },
        addTireRemoval() {
            if (!this.tire_removal.status) {
                this.textError = 'Selecciona un estado';
                this.snackbar = true;
                return;
            }
            /*if (this.tire_removal.status === 4 && !this.tire_removal.dot) {
                this.textError = 'Ingresa un valor para DOT';
                this.snackbar = true;
                return;
            }*/
            if (!this.tire_removal.rtd) {
                this.textError = 'Ingresa un valor para el RTD';
                this.snackbar = true;
                return;
            }
            this.currentTireRemoval.rtd = this.tire_removal.rtd;
            this.currentTireRemoval.dot = this.tire_removal.dot;
            this.currentTireRemoval.status = this.tire_removal.status;
            this.currentTireRemoval.part_id = this.tire_removal.part_id;
            this.currentTireRemoval.fail_id = this.tire_removal.fail_id;
            this.currentTireRemoval.causal_id = this.tire_removal.causal_id;
            this.currentTireRemoval.photo = this.tire_removal.photo;
            this.currentTireRemoval.photo_url = this.tire_removal.photo_url;
            this.dialog = false;
            this.buildTiresPositionFinal();
        },
        storeTires() {
            if (!this.tripCounterKm && !this.tripCounterTime) {
                this.textError = 'Debe llenar el contador de kilómetros o horas';
                this.snackbar = true;
                return;
            }
            if (!this.date) {
                this.textError = 'Debe seleccionar la fecha del movimiento';
                this.snackbar = true;
                return;
            }

            if (this.tripCounterKm) {
                if (this.tripCounterKm < 0) {
                    this.textError = 'El kilometraje ingresado no puede ser negativo';
                    this.snackbar = true;
                    return;
                }
                if (!this.reset && this.unit.km_travel > this.tripCounterKm) {
                    this.textError = 'El kilometraje ingresado debe ser mayor o igual al último registrado';
                    this.snackbar = true;
                    return;
                }
            }

            if (this.tripCounterTime) {
                if (this.tripCounterTime < 0) {
                    this.textError = 'El recorrido en horas ingresado no puede ser negativo';
                    this.snackbar = true;
                    return;
                }
                if (this.unit.time_travel > this.tripCounterTime) {
                    this.textError = 'El recorrido en horas ingresado debe ser mayor o igual al último registrado';
                    this.snackbar = true;
                    return;
                }
            }

            this.loading = true;
            this.tiresStatus0.forEach(item => {
                item.vehicle_id = null;
            });
            this.tiresStatus3.forEach(item => {
                item.vehicle_id = null;
            });
            this.tiresStatusRetirement.forEach(item => {
                item.vehicle_id = null;
            });

            const data = {
                assigned_tires: this.tiresPositionFinal,
                tires_in_store: this.tiresStatus0,
                tires_in_transit: this.tiresStatus3,
                tires_in_retirement: this.tiresStatusRetirement,
                trip_counter_km: this.tripCounterKm,
                reset: this.reset,
                trip_counter_time: this.tripCounterTime,
                date: this.date,
            };
            unitService.storeTires(this.unit.id, data).then(response => {
                this.$router.push({path: '/units'})
            }).catch(error => {
                this.textError = error.response.data.message;
                this.snackbar = true;
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>
label {
    font-weight: 700;
}

.div-eje {
    width: 268px;
    height: 707px;
    background-repeat: no-repeat;
    margin: 0 auto
}

.table-div {
    max-height: 20rem;
    overflow-y: auto;
}

.tire1empty {
    position: absolute;
    width: 44px;
    height: 110px;
    margin-left: 5px;
    margin-top: 37px;
}

.tire1 {
    position: absolute;
    width: 27px;
    height: 110px;
    margin-left: 0;
    margin-top: 1px;
    background: #000;
}

.tire2empty {
    position: absolute;
    width: 44px;
    height: 110px;
    margin-left: 215px;
    margin-top: 37px;
}

.tire3empty {
    position: absolute;
    width: 44px;
    height: 110px;
    margin-left: 5px;
    margin-top: 180px;
}

.tire4empty {
    position: absolute;
    width: 44px;
    height: 110px;
    margin-left: 215px;
    margin-top: 180px;
}

.tire5empty {
    position: absolute;
    width: 44px;
    height: 110px;
    margin-left: 4px;
    margin-top: 423px;
}

.tire6empty {
    position: absolute;
    width: 44px;
    height: 110px;
    margin-left: 46px;
    margin-top: 423px;
}

.tire7empty {
    position: absolute;
    width: 44px;
    height: 110px;
    margin-left: 189px;
    margin-top: 423px;
}

.tire8empty {
    position: absolute;
    width: 44px;
    height: 110px;
    margin-left: 231px;
    margin-top: 423px;
}

.tire9empty {
    position: absolute;
    width: 44px;
    height: 110px;
    margin-left: 19px;
    margin-top: 573px;
}

.tire10empty {
    position: absolute;
    width: 44px;
    height: 110px;
    margin-left: 216px;
    margin-top: 573px;
}

img {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.custom-font-size-input {
    font-size: 1.1em;
}

.custom-font-size {
    font-size: .7em;
}

.custom-font-size .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: .9em;
}

.custom-font-size .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: .9em;
}
</style>
